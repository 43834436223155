/**개인정보 동의서 */
export const listData = [
  `1. Purpose of collection and use of personal information
- To confirm identification and check departure for V.A.T. refund of foreign tourists received cosmetic surgery`,
  `2. Categories for collecting personal information
- Personal Information: Name, Date of Birth, Nationality, Passport No., Date of Departure
- Unique Identification Information: Passport No.`,
  `3. Period of holding and using personal information
- The taxation period which includes the date when the V.A.T. refund or remittance is received`,
  `4. Withdrawal of consent and contents of disadvantage
- You have right to withdraw consents. However, consequence follows(You may only receive V.A.T. refund at the refund counter within the airport or port of departure)
- Personal information will not be disclosed for any other collected purpose`,
];
export const customerDetailData = {
  title: '▣ Customer Details',
  cols: ['Passport Full name', 'Nationality', 'Passport No', '* E-mail'],
};
export const agreementData = [
  'I will consent to disclosure of personal information.',
  'I will consent to disclosure of unique identification information.',
];

/**서비스 가이드 */
export const guideContentData = [
  {
    title: '■ People who can get tax refunds for plastic surgery in Korea.',
    content: `- Foreigners who are scheduled to leave within 6 months of stay and within 3 months from the date of treatment
    ※ Income from domestic business activities (employment visas) is generated, or foreigners with a stay of more than 6 months cannot get a tax refund.
    ※ Tax refund is not possible when leaving the country after 3 months from the date of treatment
- Overseas residents living abroad for at least two years and staying in Korea for less than three months`,
  },
  {
    title: "■ People who can't get a tax refund for plastic surgery in Korea.",
    content: `- corporation / a diplomat stationed in Korea / United Nations and U.S. soldiers stationed in South Korea, civilian worker in the military`,
  },
  {
    title: '■ TAX REFUND Procedure Guide',
  },
];
export const procedureData = [
  {
    title: `①   Complete the contents and signature on the [Document 1] Medical Service Supply Confirmation and [Document 2] Consent to use personal information to this notice.`,
  },
  {
    title: `②   Submit the document to the staff of the medical institution when it is complete.`,
    underline: `※ [Document 1] Part 1 is for submission to medical institutions, and part 1 is for customer storage.`,
  },
  {
    title: `③   Pay with VAT included.`,
  },
  {
    title: `④   Email related to tax refund is sent within one to two days through the e-mail address written in the [Document 2] consent to use personal information.`,
    underline: `※ If the email address is different, it may be difficult to refund the tax, so please enter the correct address.`,
  },
  {
    title: `⑤   Please write down the bank information to receive the tax refund in the mail and reply`,
    underline: `After leaving the country, the tax refund will be made with the bank information that you replied to by email within 3 weeks.`,
    content: `※ Since departure is confirmed electronically, there is no separate customs inspection or airport work at the time of departure.`,
  },
];
