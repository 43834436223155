import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAppDispatch } from '@/hooks/useReduxHooks';
import { addPriceData, payCaculate } from '@/store/modules/refund';
import SupplyInputItem from './SupplyInputItem';
import { IOrderInfoList } from '@/types/refund';

function SupplyInput() {
  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const intialCard = {
    productType: '',
    price: '',
    supplyPrice: '',
    vatPrice: '',
  };

  const { fields, append, remove } = useFieldArray({
    control: { ...methods.control },
    name: 'supply',
  });

  const onAddClick = () => {
    append({
      productType: '',
      price: '',
      supplyPrice: '',
      vatPrice: '',
      isCheck: false,
    });
    dispatch(addPriceData(intialCard));
  };
  // 선택 삭제
  const onRemoveClick = async () => {
    const supplyList = methods.getValues().supply;
    const removeArray: number[] = [];

    await supplyList.forEach((item: IOrderInfoList, index: number) => {
      if (item.isCheck) {
        removeArray.push(index);
      }
    });

    removeArray.forEach((item) => {
      methods.setValue(`supply.${item}.price`, '');
      methods.setValue(`supply.${item}.productType`, '');
      methods.setValue(`supply.${item}.supplyPrice`, '');
      methods.setValue(`supply.${item}.vatPrice`, '');
      const payload = {
        price: Number(
          methods.getValues(`supply.${item}.price`).replace(/(,)/g, '')
        ),
        supplyPrice: Number(
          methods.getValues(`supply.${item}.supplyPrice`).replace(/(,)/g, '')
        ),
        vatPrice: Number(
          methods.getValues(`supply.${item}.vatPrice`).replace(/(,)/g, '')
        ),
        index: item,
      };
      dispatch(payCaculate(payload));
    });
    remove(removeArray.reverse());
  };

  return (
    <>
      <InputList>
        {fields.map((item, index) => (
          <SupplyInputItem
            key={item.id}
            index={index}
            onRemoveClicks={onRemoveClick}
          />
        ))}
      </InputList>
      <ButtonWrapper onClick={onAddClick}>
        <AddButton>+ 시술 추가</AddButton>
      </ButtonWrapper>
    </>
  );
}

const InputList = styled.ul``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #eaf0fa;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  border-top: none;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  color: ${(props) => props.theme.grayColors.scale2};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
`;

export default SupplyInput;
