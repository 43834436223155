import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isValidate: false,
  isRefund: false,
  isPrint: false,
  isUpload: false,
  isRefundFinal: false,
};

const refundSlice = createSlice({
  name: 'validate',
  initialState,
  reducers: {
    validateReset: () => initialState,
    passportValidateState: (state, action) => {
      state.isValidate = action.payload;
    },
    refundValidateState: (state, action) => {
      state.isRefund = action.payload;
    },
    printValidateState: (state) => {
      state.isPrint = true;
    },
    uploadValidateState: (state) => {
      state.isUpload = true;
    },
    refundFinalValidateState: (state) => {
      state.isRefundFinal = true;
    },
  },
});

export const {
  validateReset,
  passportValidateState,
  refundValidateState,
  printValidateState,
  uploadValidateState,
  refundFinalValidateState,
} = refundSlice.actions;
export default refundSlice.reducer;
