import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import downArrowIcon from '@/assets/common/downArrow.png';
import { ProductTypeList } from '@/constants/supply';
import { useAppSelecotr } from '@/hooks/useReduxHooks';

interface IProps {
  name: string;
}

interface IDropList {
  key: number;
  value: string;
}

function SelectBox({ name }: IProps) {
  const [selectValue, setSelectValue] = useState('');
  const [isDrop, setIsDrop] = useState(false);
  const methods = useFormContext();
  const { productTypes } = useAppSelecotr<any>((state) => state.user);

  const [medicalTypeList, setMedicalTypeList] = useState<
    IDropList[] | undefined
  >([]);
  const isOneLength = productTypes.length === 1;

  const onMouseDown = (dropList: IDropList) => {
    setSelectValue(dropList.value);
    setIsDrop(false);
    methods.setValue(name, dropList.key);
  };

  // 시술명 초기화
  useEffect(() => {
    if (isOneLength) {
      return;
    }

    if (!String(methods.getValues(name))) {
      setSelectValue('');
    }
  }, [methods]);

  useEffect(() => {
    if (productTypes.length === 0) {
      setMedicalTypeList(ProductTypeList);
      return;
    }
    if (isOneLength) {
      const item = productTypes[0];
      methods.setValue(name, ProductTypeList[item].key);
      setSelectValue(ProductTypeList[item].value);
      return;
    }

    const newArray = ProductTypeList.filter((item) =>
      productTypes.includes(String(item.key))
    );

    setMedicalTypeList(newArray);
  }, []);

  return (
    <Container>
      <DropContainer
        onClick={() => setIsDrop((prev) => !prev)}
        onBlur={() => setIsDrop(false)}
      >
        <Select {...methods.register(name)} isDisplay />
        <Select
          value={selectValue}
          placeholder='시술명'
          readOnly
          isSelect={selectValue}
        />

        {!isOneLength && (
          <ImageContainer>
            <Image src={downArrowIcon} />
          </ImageContainer>
        )}
      </DropContainer>
      {!isOneLength && (
        <DropList isDrop={isDrop}>
          {medicalTypeList &&
            medicalTypeList.map((option) => (
              <DropItem
                key={option.key}
                onMouseDown={() => onMouseDown(option)}
              >
                <Text>{option.value}</Text>
              </DropItem>
            ))}
        </DropList>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 85%;
  text-align: center;
`;

const DropContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  position: relative;
`;

const Select = styled.input<{ isSelect?: string; isDisplay?: boolean }>`
  display: ${(props) => (props.isDisplay ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.isSelect && props.theme.priamryColors.primary};
  background-color: ${(props) => (props.isSelect ? 'none' : 'transparent')};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
    text-align: center;
  }
`;

const Text = styled.span<{ isValue?: string }>`
  color: ${(props) =>
    props.isValue
      ? props.theme.priamryColors.primary
      : props.theme.grayColors.scale3};
`;

const ImageContainer = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;

const Image = styled.img`
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const DropList = styled.ul<{ isDrop: boolean }>`
  position: absolute;
  top: 40px;
  padding: 20px 16px;
  width: 100%;
  max-height: 170px;
  border-radius: 4px;
  overflow-y: auto;
  flex-direction: column;
  z-index: 10;
  display: ${(props) => (props.isDrop ? 'flex' : 'none')};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const DropItem = styled.li`
  cursor: pointer;
  padding: 5px 0;
  :hover {
    background-color: ${(props) => props.theme.priamryColors.primaryVarient};
    span {
      color: ${(props) => props.theme.priamryColors.primary};
    }
  }
`;

export default SelectBox;
