import React from 'react';
import styled from 'styled-components';

import { agreementData, customerDetailData, listData } from '@/constants/print';
import FlexWrap from '@/components/common/FlexWrap';

function ContentToCollect() {
  return (
    <Container>
      <Title>{`< Consent to collect, use and disclose of personal information >`}</Title>
      <Table>
        <Row height={550}>
          <Cell>
            <Lists>
              {listData.map((item, idx) => (
                <ListItem key={idx}>{item}</ListItem>
              ))}
            </Lists>
          </Cell>
        </Row>
        {agreementData.map((item, idx) => (
          <Row key={idx}>
            <Cell flex='5'>{item}</Cell>
            <Cell>□</Cell>
          </Row>
        ))}
      </Table>
      <SubTitle>{customerDetailData.title}</SubTitle>
      <Table>
        {customerDetailData.cols.map((item, idx) => (
          <Row key={idx}>
            <Cell flex='3' bold={idx === 3}>
              {item}
            </Cell>
            <Cell flex='7'></Cell>
          </Row>
        ))}
      </Table>
      <BottomText>
        We will refund the tax to the information provided in the email. Please
        fill in accurately.
      </BottomText>
      <RightText>
        {` 20        .        .        .
        Name:                                                    (Signature)`}
      </RightText>
    </Container>
  );
}
const Container = styled.div`
  height: 1680px;
  padding: 70px;
  width: 100%;
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  padding: 30px 0;
  border-bottom: 2px solid gray;
  border-top: 2px solid gray;
  margin: 30px 0;
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
`;
const SubTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0 15px;
  text-align: center;
  width: 100%;
`;
const Table = styled.div`
  width: 1060px;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
`;
const Row = styled(FlexWrap)<{ height?: number; style?: any }>`
  height: ${(props) => props.height || 40}px;
  width: 100%;
`;
const Cell = styled(FlexWrap)<{
  height?: number;
  flex?: string;
  bold?: boolean;
}>`
  height: 100%;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.flex || 1};
  font-weight: ${(props) => (props.bold ? 700 : 500)};
`;
const Lists = styled.ul`
  padding: 20px 40px 0px;
  text-align: left;
`;
const ListItem = styled.li`
  text-indent: -20px;
  padding-left: 20px;
  margin-bottom: 20px;
  white-space: pre-wrap;
  line-height: 2;
`;
const RightText = styled.p`
  width: 100%;
  text-align: right;
  font-size: 20px;
  line-height: 2;
  margin: 40px 0 16px;
  white-space: pre-wrap;
`;
const BottomText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
`;
export default ContentToCollect;
