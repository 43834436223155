import React, { ChangeEvent } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Typography from '../common/Typography';

function RefundMemo() {
  const { register } = useForm();
  const methods = useFormContext();

  const onChangeMemo = (e: ChangeEvent<HTMLTextAreaElement>) => {
    methods.setValue('memo', e.target.value);
  };

  return (
    <Container>
      <Typography
        size='20px'
        lineHeight='28px'
        fontWeight='500'
        margin='0 0 24px 0'
      >
        메모{' '}
        <Typography size='16px' lineHeight='24px' fontWeight='500'>
          (선택사항)
        </Typography>
      </Typography>
      <TextArea
        {...register('memo')}
        onChange={onChangeMemo}
        placeholder='메모를 작성해주세요.'
      />
    </Container>
  );
}
const Container = styled.div``;
const TextArea = styled.textarea`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  width: 100%;
  min-height: 80px;
  padding: 20px;
  border: 1px solid #cbccce;
  resize: none;
  ::placeholder {
    color: #cbccce;
  }
`;
export default RefundMemo;
