import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { AxiosError } from 'axios';

import { refundRegister } from '@/api/refund';
import { IRefundPayload, IRefundResponse, ISubmitData } from '@/types/refund';
import AmountDetail from './AmountDetail';
import SupplyDetail from './SupplyDetail';
import TouristInput from './TouristInput';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useAppDispatch, useAppSelecotr } from '@/hooks/useReduxHooks';
import { refundIndexState } from '@/store/modules/user';
import LoadingView from '../common/LoadingView';
import { reset } from '@/store/modules/refund';
import HospitalSelect from './HospitalSelect';
import RefundMemo from './RefundMemo';

const orderInfoList = {
  productType: '',
  price: '',
  supplyPrice: '',
  vatPrice: '',
  isCheck: false,
};

interface IProps {
  setIsRefund: Dispatch<SetStateAction<boolean>>;
}

interface ISupplyItem {
  price: string;
  productType: string | number;
  supplyPrice: string;
  vatPrice: string;
  isCheck?: boolean;
}

function RefundContent({ setIsRefund }: IProps) {
  const [isValidate, setIsValidate] = useState(false);
  const [isPayment, setIsPayment] = useState(true);
  const { touristIndex } = useAppSelecotr((state) => state.user);

  const dispatch = useAppDispatch();

  // 환급하기 할때 서버로 보내는 body 값
  const methods = useForm({
    defaultValues: {
      supplyDate: '',
      departureDate: '',
      paymentCard: '0',
      paymentCash: '0',
      supply: [orderInfoList],
      hospitalIndex: '',
      refundCorp: 'MEDIVISOR',
      memo: '',
    },
  });
  const { handleSubmit } = methods;

  const { mutate: refundMutation, isLoading } = useMutation<
    IRefundResponse,
    AxiosError,
    IRefundPayload
  >((payload) => refundRegister(payload), {
    retry: false,
    onSuccess: (data) => {
      dispatch(refundIndexState(data));
      dispatch(reset());
      setIsRefund(true);
      setIsPayment(false);
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const onSubmit: SubmitHandler<ISubmitData> = (data) => {
    let totalPrice = 0;
    let totalRefund = 0;

    const paymentCard = data.paymentCard.replace(/(,)/g, '');
    const paymentCash = data.paymentCash.replace(/(,)/g, '');
    const newSupply = data.supply.filter(
      (item) => item.price && String(item.productType)
    );

    newSupply.forEach((item: ISupplyItem) => {
      item.productType = +item.productType;
      delete item.isCheck;
    });

    if (newSupply.length === 0) {
      return;
    }

    data.supply.forEach((item) => {
      item.price = item.price.replace(/(,)/g, '');
      totalPrice += Number(item.price);
    });
    totalRefund = getRefundAmount(totalPrice);

    // 병원 선택 validate
    if (!data.hospitalIndex) return;

    // - 금액
    if (+paymentCard < 0) {
      alert('올바른 금액을 입력하세요.');
      return;
    }

    const payload: IRefundPayload = {
      hospitalIndex: data.hospitalIndex,
      refundCorp: data.refundCorp,
      memo: data.memo,
      supplyDate: data.supplyDate,
      totalRefund: String(totalRefund),
      totalPrice: String(totalPrice),
      paymentCard: String(paymentCard),
      paymentCash: String(paymentCash),
      touristId: String(touristIndex),
      orderInfoList: newSupply,
    };
    refundMutation(payload);
  };
  return (
    <>
      <Title>환급하기</Title>
      <FormProvider {...methods}>
        <Container>
          <TouristInput isValidate={isValidate} setIsValidate={setIsValidate} />
          <HospitalSelect />
          <SupplyDetail />
          <AmountDetail isPayment={isPayment} />
          <RefundMemo />
          <Button
            isValidate={isValidate}
            disabled={!isValidate || isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? <LoadingView /> : '확인'}
          </Button>
        </Container>
      </FormProvider>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  flex: 1;
  padding: 28px 40px 85px 40px;
  @media ${(props) => props.theme.laptop} {
    gap: 24px;
  }
`;

const Title = styled.h1`
  padding: 22px 0px 23px 40px;
  border-bottom: 1px solid #cbccce;
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;

const Button = styled.button<{ isValidate: boolean }>`
  width: 100px;
  height: 48px;
  border-radius: 4px;
  align-self: flex-end;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.isValidate
      ? props.theme.pointColors.white
      : props.theme.grayColors.scale2};
  background-color: ${(props) =>
    props.isValidate
      ? props.theme.grayColors.scale1
      : props.theme.grayColors.scale4};
`;

export default RefundContent;
