import React, { useState } from 'react';
import styled from 'styled-components';

import MedicalInfoContent from '@/components/medicalInfo/MedicalContent';
import PasswordChange from '@/components/medicalInfo/PasswordChange';
import Footer from '@/components/common/Footer';

function Medical() {
  return (
    <Container>
      <MedicalInfoContent />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1500px;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

export default Medical;
