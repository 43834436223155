import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { AxiosError } from 'axios';

import FlexWrap from '../common/FlexWrap';
import Modal from '../common/Modal';
import { refundRemittance } from '@/api/refund';
import { useAppSelecotr } from '@/hooks/useReduxHooks';
import { useRefndDetail } from '@/hooks/useRefundDetail';
import { IRefundDetailContent } from '@/types/refund';
import { refundDetailColor, refundDetailText } from '@/util/common';
import { comma } from '@/util/format';
import Typography from '../common/Typography';

type RefundOutlineProps = {
  refund: IRefundDetailContent;
};

type medicalProductsType = {
  label: string;
  price: string;
  vat: string;
};

function RefundOutline({ refund }: RefundOutlineProps) {
  const [isModal, setIsModal] = useState(false);
  const [medicalProducts, setMedicalProducts] = useState<medicalProductsType[]>(
    []
  );
  const { refetch } = useRefndDetail();
  const { productAllDto } = useAppSelecotr((state) => state.product);
  const isDisable = refund.refundStatus === 'CANCEL' || refund.isTransfer;

  useEffect(() => {
    if (productAllDto.length < 1) return;
    const newArray: any[] = [];
    productAllDto.forEach((product: any) => {
      if (+product.price > 0) {
        newArray.push(product);
      }
    });
    setMedicalProducts(newArray);
  }, [productAllDto]);

  const RemittanceMutation = useMutation<boolean, AxiosError, number>(
    'refundCancle',
    (payload) => refundRemittance(payload),
    {
      retry: false,
      onSuccess: () => {
        refetch();
        setIsModal(false);
      },
      onError: (error) => {
        console.log('에러', error);
      },
    }
  );
  const handleProductType = () => {
    const newProduct = medicalProducts[0].label.split(' ')[2];
    return medicalProducts.length - 1 > 0
      ? `${newProduct} 외 ${medicalProducts.length - 1}`
      : newProduct;
  };

  const onClickRemittance = () => {
    RemittanceMutation.mutate(refund.refundIndex);
  };

  return (
    <Wrapper>
      <FlexWrap>
        <Typography size='18px' lineHeight='26px' fontWeight='500'>
          환급 개요
        </Typography>
      </FlexWrap>
      <Container>
        <Line>
          <ContentContainer>
            <Title>환급 일자</Title>
            <Content>{refund.supplyDate}</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>송금 상태</Title>
            <Content>
              <Typography
                color={`${refundDetailColor(
                  refund.refundStatus,
                  refund.isTransfer
                )}`}
              >
                {refundDetailText(refund.refundStatus, refund.isTransfer)}
              </Typography>
            </Content>
            <Button disabled={isDisable} onClick={() => setIsModal(true)}>
              송금 완료
            </Button>
          </ContentContainer>
        </Line>
        <Line>
          <ContentContainer>
            <Title>병원명</Title>
            <Content>{refund.hospitalName}</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>시술명</Title>
            <Content>{medicalProducts[0] && handleProductType()}</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>환급 관리</Title>
            <Content>{refund.refundCorp}</Content>
          </ContentContainer>
        </Line>
        <Line>
          <ContentContainer>
            <Title>환자명</Title>
            <Content>{refund.touristName}</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>국적</Title>
            <Content>{refund.nation}</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>여권번호</Title>
            <Content>{refund.touristPassportNumber}</Content>
          </ContentContainer>
        </Line>
        <Line isNotBottomBorder>
          <ContentContainer>
            <Title>의료금액</Title>
            <Content>{comma(refund.totalPrice)}원</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>환급액</Title>
            <Content>{comma(refund.refund)}원</Content>
          </ContentContainer>
          <ContentContainer>
            <Title isLeftBorder>결제금액</Title>
            <Content>{comma(refund.totalPrice)}원</Content>
          </ContentContainer>
        </Line>
      </Container>
      {isModal && (
        <Modal
          title='송금 완료'
          content={
            '송금 완료로 변경하시겠습니까?\n송금 완료 시 환급 취소가 불가합니다.'
          }
          onCancle={() => setIsModal(false)}
          onComfirm={onClickRemittance}
        />
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div``;
const Container = styled.div`
  margin-top: 12px;
  border-top: 2px solid #cbccce;
  border-bottom: 2px solid #cbccce;
`;
const Line = styled(FlexWrap)<{ isNotBottomBorder?: boolean }>`
  align-items: center;
  border-bottom: ${(props) =>
    props.isNotBottomBorder ? 'none' : ' 1px solid #cbccce'};
`;
const ContentContainer = styled(FlexWrap)`
  position: relative;
  flex: 1;
  background-color: #fff;
`;
const Title = styled.div<{ isLeftBorder?: boolean }>`
  width: 120px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 12px 20px;
  background-color: #eaf0fa;
  border-right: 1px solid #cbccce;
  border-left: ${(props) =>
    props.isLeftBorder ? ' 1px solid #cbccce' : 'none'};
`;
const Content = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  padding: 13px 20px;
  background-color: #f8fafc;
  flex: 1;
`;
const Button = styled.button`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  position: absolute;
  padding: 4px 20px;
  border: 1px solid #030303;
  background-color: #fff;
  border-radius: 4px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  :hover {
    opacity: 0.8;
  }
  :disabled {
    border: 2px solid #cbccce;
    color: #cbccce;
  }
`;
export default RefundOutline;
