import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAppDispatch, useAppSelecotr } from '@/hooks/useReduxHooks';
import { totalPrice } from '@/store/modules/refund';
import { getComma } from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useFormContext } from 'react-hook-form';
import Typography from '../common/Typography';
import FlexWrap from '../common/FlexWrap';

function AmountDetail({ isPayment }: any) {
  const [totalPayPrice, setTotalPayPrice] = useState(0);
  const [paymentCard, setPaymentCard] = useState('');
  const [paymentCash, setPaymentCash] = useState('0');
  const [refundCorp, setRefundCorp] = useState('MEDIVISOR');
  const name = localStorage.getItem('name');

  let paymentCardRef = useRef<string | number>(0);
  let paymentCashRef = useRef<string | number>(0);
  const { isAutoPayment } = useAppSelecotr((state) => state.user);

  const methods = useFormContext();
  const refundInfoList = useAppSelecotr((state) => state.refund.priceList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let newTotalPayPrice = 0;

    refundInfoList.forEach((item: any) => {
      newTotalPayPrice += Number(String(item.price).replace(/(,)/g, ''));
    });
    setTotalPayPrice(newTotalPayPrice);

    dispatch(
      totalPrice({
        totalPrice: totalPayPrice,
        totalRefund: getRefundAmount(totalPayPrice),
      })
    );
  }, [refundInfoList]);

  const paymentTypeCheck = () => {
    return totalPayPrice;
  };

  useEffect(() => {
    setPaymentCard(getComma(paymentTypeCheck()));
    paymentCardRef.current = getComma(paymentTypeCheck());
    if (methods) {
      methods.setValue('paymentCard', paymentCardRef.current);
    }
  }, [totalPayPrice]);

  const onChangeCash = (e: any) => {
    const newValue = e.target.value.replace(/(,)/g, '');
    const finalPrice = paymentTypeCheck() - newValue;

    setPaymentCard(getComma(finalPrice));
    setPaymentCash(getComma(newValue));

    methods.setValue('paymentCard', getComma(finalPrice));
    methods.setValue('paymentCash', getComma(newValue));
  };

  const onChangeRefundCorp = (way: string) => {
    methods.setValue('refundCorp', way);
    setRefundCorp(way);
  };

  return (
    <Wrapper>
      <Typography
        size='20px'
        lineHeight='28px'
        fontWeight='500'
        margin='0 0 12px 0'
      >
        금액 상세
      </Typography>
      <InputContainer>
        <InputItem>
          <RefundLabel>환급 방법</RefundLabel>
          <RefundMethodContainer gap='16px'>
            <RefundMethod onClick={() => onChangeRefundCorp('메디바이저')}>
              <StyledRadio
                type='radio'
                value='메디바이저'
                checked={refundCorp === '메디바이저'}
              />
              <Typography size='16px' lineHeight='24px' fontWeight='400'>
                {name}
              </Typography>
            </RefundMethod>
            <RefundMethod onClick={() => onChangeRefundCorp('KTP')}>
              <StyledRadio
                type='radio'
                value='KTP'
                checked={refundCorp === 'KTP'}
              />
              <Typography size='16px' lineHeight='24px' fontWeight='400'>
                KTP
              </Typography>
            </RefundMethod>
          </RefundMethodContainer>
        </InputItem>
      </InputContainer>
      <Container isPayment={isPayment} isFullPayment={!isAutoPayment}>
        <InputContainer>
          <InputItem>
            <RefundLabel>예상환급액</RefundLabel>
            <RefundText>
              {getComma(getRefundAmount(totalPayPrice))} 원
            </RefundText>
          </InputItem>
          <InputItem>
            <RefundLabel isNotBorder>결제금액</RefundLabel>
            <RefundText>{getComma(totalPayPrice)} 원</RefundText>
          </InputItem>
        </InputContainer>
        <InputContainer>
          <InputItem>
            <RefundLabel>카드 결제</RefundLabel>
            <RefundInput readOnly type='text' value={paymentCard} />
          </InputItem>
          <InputItem>
            <RefundLabel isNotBorder>현금 결제</RefundLabel>
            <RefundInput
              type='text'
              onChange={onChangeCash}
              value={paymentCash}
              placeholder='현금 결제금액 입력'
            />
          </InputItem>
        </InputContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled(FlexWrap)`
  flex-direction: column;
  gap: 12px;
`;
const Container = styled.div<{ isPayment?: boolean; isFullPayment?: boolean }>`
  display: flex;
  gap: 40px;
  width: 100%;
  justify-content: space-between;
  @media ${(props) => props.theme.laptop} {
    ${(props) =>
      !props.isFullPayment &&
      css`
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      `}
  }
`;
const InputContainer = styled(FlexWrap)`
  width: 100%;
  flex: 1;
`;
const InputItem = styled(FlexWrap)`
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const RefundMethodContainer = styled(FlexWrap)`
  align-items: center;
  flex: 5;
  border: 1px solid #cbccce;
  padding: 12px 29px;
  background-color: #fff;
`;
const RefundMethod = styled(FlexWrap)`
  cursor: pointer;
  gap: 8px;
`;
const RefundLabel = styled.p<{ isNotBorder?: boolean }>`
  text-align: center;
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 12px 29px;
  background-color: #eaf0fa;
  border: 1px solid #cbccce;
  border-right: none;
  border-left: ${(props) => props.isNotBorder && 'none'};
`;
const RefundText = styled.p`
  text-align: right;
  flex: 2;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 12px 29px;
  background-color: #fff;
  border: 1px solid #cbccce;
`;
const RefundInput = styled.input`
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #cbccce;
  flex: 2;
  padding: 12px 29px;
  text-align: right;
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const StyledRadio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #cbccce;
  cursor: pointer;
  &::disabled {
    cursor: default;
  }
  &:checked {
    border: 4px solid #354eff;
    background-color: #ffffff;
  }
`;
export default AmountDetail;
