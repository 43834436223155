import { createSlice } from '@reduxjs/toolkit';

const priceData = {
  price: '',
  supplyPrice: '',
  vatPrice: '',
};

const initialState = {
  totalPrice: '',
  totalRefund: '',
  priceList: [priceData],
  hospitalIndex: null,
};

const refundSlice = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    reset: () => initialState,
    addPriceData: (state, action) => {
      state.priceList.push(action.payload);
    },
    removePriceData: (state, action) => {
      state.priceList.splice(action.payload.index, 1);
    },
    payCaculate: (state, action) => {
      state.priceList[action.payload.index].price = action.payload.price;
      state.priceList[action.payload.index].vatPrice = action.payload.vatPrice;
      state.priceList[action.payload.index].supplyPrice =
        action.payload.supplyPrice;
    },
    totalPrice: (state, action) => {
      state.totalPrice = action.payload.totalPrice;
      state.totalRefund = action.payload.totalRefund;
    },
    hospitalIndexSet: (state, action) => {
      return { ...state, hospitalIndex: action.payload };
    },
    updateRefundData: (state, action) => {
      return { ...state, action };
    },
  },
});

export const {
  reset,
  addPriceData,
  removePriceData,
  payCaculate,
  totalPrice,
  hospitalIndexSet,
} = refundSlice.actions;
export default refundSlice.reducer;
