import React, { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Refund from '@/pages/Refund';
import RefundDetail from '@/pages/RefundDetail';
import { useAppDispatch, useAppSelecotr } from '@/hooks/useReduxHooks';
import Login from '@/pages/Login';
import { loginUserState } from '@/store/modules/user';
import Home from '@/pages/Home';
import Medical from '@/pages/Medical';
import Notice from '@/pages/Notice';
import SideNavigation from '@/components/SideNavigation';
import styled from 'styled-components';
import NoticeDetail from '@/components/notice/NoticeDetail';
import { isMobile } from 'react-device-detect';
import NotPage from '@/pages/NotPage';

const RouterContainer = () => {
  const { isLogged } = useAppSelecotr((state) => state.user);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (localStorage.getItem('loginToken')) {
      dispatch(loginUserState(true));
    }
  }, []);

  return (
    <Router>
      <Wrapper className='notranslate'>
        {isMobile ? (
          <NotPage />
        ) : (
          <>
            {isLogged && <SideNavigation />}
            {isLogged ? (
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/refund' element={<Refund />} />
                <Route path='/refund-detail' element={<RefundDetail />} />
                <Route path='/medical-info' element={<Medical />} />
                <Route path='/notice' element={<Notice />}>
                  <Route path='detail' element={<NoticeDetail />} />
                </Route>
              </Routes>
            ) : (
              <Routes>
                <Route path='/' element={<Login />} />
              </Routes>
            )}
          </>
        )}
      </Wrapper>
    </Router>
  );
};

const Wrapper = styled.div`
  display: flex;
  max-width: 100vw;
`;

export default RouterContainer;
