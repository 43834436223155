import React, { useEffect } from 'react';
import styled from 'styled-components';

import RefundDetailContent from '@/components/RefundDetail/RefundDetailContent';
import Footer from '@/components/common/Footer';

function RefundDetail() {
  return (
    <Container>
      <Title>환급하기</Title>
      <RefundDetailContent />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;
const Title = styled.h1`
  padding: 22px 0px 23px 40px;
  border-bottom: 1px solid #cbccce;
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;
export default RefundDetail;
