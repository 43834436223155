import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import SupplyInputBtn from './SupplyInputBtn';
import removeIcon from '@/assets/common/remove.png';
import SelectBox from '@/components/common/SelectBox';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { payCaculate } from '@/store/modules/refund';
import {
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
} from '@/util/format';
import Text from '@/components/common/Text';

interface IProps {
  index: number;
  onRemoveClicks: (index: number) => void;
}

interface IChange {
  index: number;
  e: any;
}

function SupplyInputItem({ index, onRemoveClicks }: IProps) {
  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const isSurgery = methods.getValues(`supply.${index}.productType`);

  const onChange = ({ e: { target }, index }: IChange) => {
    methods.setValue(
      `supply.${index}.price`,
      target.value.replace(/[^0-9]/g, '')
    );

    let newValue = target.value.replace(/(,)/g, '');

    // 결제금액
    methods.setValue(`supply.${index}.price`, getComma(newValue));
    // 공급가액
    methods.setValue(
      `supply.${index}.supplyPrice`,
      getComma(getSupplyPaymentPrice(newValue))
    );
    // 부가세
    methods.setValue(
      `supply.${index}.vatPrice`,
      getComma(
        getVatPaymentPrice(newValue, getSupplyPaymentPrice(newValue).toString())
      )
    );
    const payload = {
      price: Number(
        methods.getValues(`supply.${index}.price`).replace(/(,)/g, '')
      ),
      supplyPrice: Number(
        methods.getValues(`supply.${index}.supplyPrice`).replace(/(,)/g, '')
      ),
      vatPrice: Number(
        methods.getValues(`supply.${index}.vatPrice`).replace(/(,)/g, '')
      ),
      index,
    };
    dispatch(payCaculate(payload));
  };

  return (
    <Wrapper>
      <InputItem>
        <Item flex={1}>{index < 9 ? '0' + (index + 1) : index + 1}</Item>
        <Item flex={4}>
          <SelectBox name={`supply.${index}.productType`} />
        </Item>
        <Item flex={3}>
          <Input
            {...methods.register(`supply.${index}.price`, {
              onChange: (e) => onChange({ e, index }),
            })}
            disabled={isSurgery === ''}
            placeholder='금액 입력'
          />
          원
        </Item>
        <Item flex={3}>
          <Input
            {...methods.register(`supply.${index}.supplyPrice`)}
            placeholder='공급가액'
            disabled
          />{' '}
          원
        </Item>
        <Item flex={3}>
          <Input
            {...methods.register(`supply.${index}.vatPrice`)}
            placeholder='부가세액'
            disabled
          />
          원
        </Item>
        <SupplyInputBtn index={index} />
      </InputItem>
      <RemoveWrapper>
        <RemoveIcon src={removeIcon} onClick={() => onRemoveClicks(index)} />
        <Text text='삭제' color='#757575' />
      </RemoveWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const InputItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const Item = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  flex: ${(props) => props.flex};
  line-height: ${(props) => props.theme.lineHeight.large};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

const Input = styled.input`
  width: 85%;
  height: 32px;
  padding: 5px 14px;
  border-radius: 4px;
  text-align: right;
  outline: none;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  ::placeholder {
    text-align: right;
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const RemoveWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 0;
  right: 0;
`;

const RemoveIcon = styled.img`
  width: 30px;
  height: 30px;
  padding: 2px;
  cursor: pointer;
`;

export default SupplyInputItem;
