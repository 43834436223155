import React, { useEffect, useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import styled, { css } from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import Icon from '../common/Icon';

import Typography from '../common/Typography';
import arrowDownIcon from '@/assets/common/arrowDown.png';
import cancleIcon from '@/assets/common/cancle.png';
import {
  AusBankList,
  CanBankList,
  ChnBanckList,
  ChnMethodList,
  IdnBankList,
  JpnBankList,
  SgpBankList,
  ThbBankList,
} from '@/constants/refundDetail';
import { AccountInfoType, AccountInputFormType } from './DetailModal';

type AccountInfoProps = {
  nation: string;
  register: UseFormRegister<AccountInputFormType>;
  setValue: any;
  getValues: any;
  isEdit: boolean;
  touristData?: AccountInfoType;
};

function AccountInfo({
  nation,
  touristData,
  register,
  getValues,
  setValue,
  isEdit,
}: AccountInfoProps) {
  const [isChnModal, setIsChnModal] = useState(false);
  const [chnMethod, setChnMethod] = useState(touristData?.transferMethod);
  const [selectedMethod, setSelectedMethod] = useState(
    touristData?.transferMethod
  );
  const [chnBank, setChnBank] = useState(touristData?.bank);
  const [usaAccount, setUsaAccount] = useState(
    touristData?.accountInfo || 'SAVING'
  );

  const [testNation, setTestNation] = useState('SGP');
  const onClickCancle = () => {
    setIsChnModal(false);
  };

  const onClickCloseModal = (e: any) => {
    if (e.target.className.includes('backDrop')) {
      setIsChnModal(false);
    }
  };

  const chnAccountInfo = () => {
    switch (selectedMethod) {
      case '알리페이':
        return getValues('aliPayAccount');
      case '유니온 페이':
        return getValues('cardNumber');
      case '계좌이체':
        return `${getValues('bank') || ''} ${getValues('bankAccount') || ''}`;
      default:
        return;
    }
  };

  useEffect(() => {
    setValue('cardNumber', '');
    setValue('aliPayAccount', '');
    setValue('bank', '');
    setValue('bankAccount', '');
  }, [chnMethod]);

  const onClickSubmit = () => {
    setSelectedMethod(chnMethod);
    setValue('transferMethod', chnMethod);
    setIsChnModal(false);
  };

  const onChangeAccount = (account: string) => {
    setValue('accountInfo', account);
    setUsaAccount(account);
  };

  const onChangeBank = (e: any) => {
    setChnBank(e.target.value);
    setValue('bank', e.target.value);
  };

  const remittanceMethod = () => {
    switch (chnMethod) {
      case '알리페이': {
        return (
          <>
            <LabelWrapper>
              <Label>알리페이 계정</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Input
              {...register('aliPayAccount')}
              border
              placeholder='핸드폰 번호 또는 이메일 번호 입력'
            />
          </>
        );
      }
      case '유니온 페이': {
        return (
          <>
            <LabelWrapper>
              <Label>카드 번호</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Input
              {...register('cardNumber')}
              border
              placeholder='카드번호 입력'
            />
          </>
        );
      }
      case '위챗페이': {
        return <Input hidden />;
      }
      case '계좌이체': {
        return (
          <>
            <LabelWrapper>
              <Label>은행 계좌</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <MethodInputContainer isChn disabled={!isEdit}>
              <Select
                {...register('bank')}
                isArrow
                isChnBank
                isSelected={Boolean(chnBank)}
              >
                <Option value='' hidden>
                  은행 선택
                </Option>
                {ChnBanckList.map((bank, idx) => (
                  <Option key={idx} value={bank}>
                    {bank}
                  </Option>
                ))}
              </Select>
              <Input
                {...register('bankAccount')}
                border
                placeholder='계좌번호 입력'
                isNotflex
              />
              <Typography
                size='14px'
                lineHeight='20px'
                fontWeight='400'
                color='#ED0828'
              >
                * 법인계좌는 불가합니다.
              </Typography>
            </MethodInputContainer>
          </>
        );
      }
    }
  };

  const setNationAccount = () => {
    switch (nation) {
      case 'CHN': {
        return (
          <>
            <InputContainer>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>송금방법</AccountLabel>
                  <MethodContainer
                    disabled={!isEdit}
                    onClick={() => setIsChnModal(true)}
                  >
                    <MethodSelect isSelectedMethod={Boolean(selectedMethod)}>
                      {selectedMethod ? selectedMethod : '송금방법 선택'}
                    </MethodSelect>
                    <Icon imgUrl={arrowDownIcon} width='20px' height='20px' />
                    <Typography margin='0 0 0 8px'>
                      {chnAccountInfo()}
                    </Typography>
                  </MethodContainer>
                </ContentContainer>
              </Line>
            </InputContainer>
            {isChnModal && (
              <BackDrop className='backDrop' onClick={onClickCloseModal}>
                <ModalContainer>
                  <TitleContainer>
                    <Typography size='21px' lineHeight='24px' fontWeight='700'>
                      환급계좌정보
                    </Typography>
                    <Icon
                      imgUrl={cancleIcon}
                      width='18px'
                      height='18px'
                      cursor='pointer'
                      onClick={onClickCancle}
                    />
                  </TitleContainer>
                  <DivideLine />
                  <LabelContainer>
                    <LabelWrapper>
                      <Label>송금방법</Label>
                      <RequiredDot>*</RequiredDot>{' '}
                    </LabelWrapper>
                    <SelectContainer>
                      <Select
                        isArrow
                        isSelected={Boolean(chnMethod)}
                        onChange={(e) => setChnMethod(e.target.value)}
                      >
                        <Option value='' hidden>
                          {chnMethod ? chnMethod : '송금방법 선택'}
                        </Option>
                        {ChnMethodList.map((bank, idx) => (
                          <Option key={idx} value={bank}>
                            {bank}
                          </Option>
                        ))}{' '}
                      </Select>
                      <IconContainer>
                        <Icon
                          imgUrl={arrowDownIcon}
                          width='20px'
                          height='20px'
                        />
                      </IconContainer>
                    </SelectContainer>

                    {chnMethod && remittanceMethod()}
                  </LabelContainer>
                  <DivideLine />
                  <ButtonContainer>
                    <Button onClick={onClickSubmit}>확인</Button>
                  </ButtonContainer>
                </ModalContainer>
              </BackDrop>
            )}
          </>
        );
      }
      case 'USA': {
        return (
          <InputContainer>
            <Line>
              <ContentContainer>
                <AccountLabel>휴대전화</AccountLabel>
                <Input
                  {...register('phoneNumber')}
                  placeholder='휴대전화 번호 입력'
                  disabled={!isEdit}
                  isBottomBorder
                />
              </ContentContainer>
              <ContentContainer>
                <AccountLabel isLeftBorder>Routing 번호</AccountLabel>
                <Input
                  {...register('routingNumber')}
                  placeholder='Routing 번호 입력'
                  disabled={!isEdit}
                  isBottomBorder
                />
              </ContentContainer>
            </Line>
            <Line>
              <ContentContainer>
                <AccountLabel>어카운트 정보</AccountLabel>
                <RefundMethodContainer disabled={!isEdit}>
                  <FlexWrap gap='8px'>
                    <StyledRadio
                      {...register('accountInfo')}
                      type='radio'
                      value='SAVING'
                      disabled={!isEdit}
                      onClick={() => onChangeAccount('SAVING')}
                      checked={usaAccount === 'SAVING'}
                    />
                    <Typography size='16px' lineHeight='24px' fontWeight='400'>
                      세이빙 어카운트
                    </Typography>
                  </FlexWrap>
                  <FlexWrap gap='8px'>
                    <StyledRadio
                      {...register('cashPickup')}
                      disabled={!isEdit}
                      type='radio'
                      value='CHECKING'
                      onClick={() => onChangeAccount('CHECKING')}
                      checked={usaAccount === 'CHECKING'}
                    />
                    <Typography size='16px' lineHeight='24px' fontWeight='400'>
                      체킹 어카운트
                    </Typography>
                  </FlexWrap>
                </RefundMethodContainer>
              </ContentContainer>
              <ContentContainer>
                <AccountLabel isLeftBorder>도시</AccountLabel>
                <Input
                  {...register('city')}
                  placeholder='도시명 입력 (영문만 가능)'
                  disabled={!isEdit}
                  isBottomBorder
                />
              </ContentContainer>
            </Line>
            <Line isNotBottomBorder>
              <ContentContainer>
                <AccountLabel isNotBottomBorder>주 / 지방 / 지역</AccountLabel>
                <Input
                  {...register('stateProvinceRegion')}
                  disabled={!isEdit}
                  placeholder='주 / 지방 / 지역명 입력 (영문만 가능)'
                />
              </ContentContainer>
              <ContentContainer>
                <AccountLabel isLeftBorder isNotBottomBorder>
                  우편번호
                </AccountLabel>
                <Input
                  {...register('zipPostalCode')}
                  disabled={!isEdit}
                  placeholder='우편번호 입력'
                />
              </ContentContainer>
            </Line>
          </InputContainer>
        );
      }
      case 'JPN': {
        return (
          <InputContainer>
            <Line>
              <ContentContainer>
                <AccountLabel>휴대전화</AccountLabel>
                <Input
                  {...register('phoneNumber')}
                  placeholder='휴대전화 번호 입력'
                  isBottomBorder
                  disabled={!isEdit}
                />
              </ContentContainer>
              <ContentContainer>
                <AccountLabel isLeftBorder>은행 계좌</AccountLabel>
                <MethodInputContainer disabled={!isEdit}>
                  <Select
                    {...register('bank')}
                    isFullWidth
                    isChnBank
                    isNotBorder
                    isSelected={Boolean(chnBank)}
                    onChange={(e) => onChangeBank(e.target.value)}
                  >
                    <Option value='' hidden>
                      은행 선택
                    </Option>
                    {JpnBankList.map((bank, idx) => (
                      <Option key={idx} value={bank}>
                        {bank}
                      </Option>
                    ))}
                  </Select>
                  <AccountInput
                    {...register('bankAccount')}
                    placeholder='계좌번호 입력'
                    disabled={!isEdit}
                  />
                </MethodInputContainer>
              </ContentContainer>
            </Line>
            <Line>
              <ContentContainer>
                <AccountLabel>브랜치 지점</AccountLabel>
                <Input
                  {...register('branchInformation')}
                  placeholder='브랜치 지점명 입력'
                  isBottomBorder
                  disabled={!isEdit}
                />
              </ContentContainer>
              <ContentContainer>
                <AccountLabel isLeftBorder>도시</AccountLabel>
                <Input
                  {...register('city')}
                  placeholder='도시명 입력 (영문만 가능)'
                  isBottomBorder
                  disabled={!isEdit}
                />
              </ContentContainer>
            </Line>
            <Line isNotBottomBorder>
              <ContentContainer>
                <AccountLabel isNotBottomBorder>현 / 도</AccountLabel>
                <Input
                  {...register('stateProvinceRegion')}
                  placeholder='현 행정지명 입력 (영문만 가능)'
                  disabled={!isEdit}
                />
              </ContentContainer>
              <ContentContainer>
                <AccountLabel isLeftBorder isNotBottomBorder />
                <Input disabled={!isEdit} />
              </ContentContainer>
            </Line>
          </InputContainer>
        );
      }
      case 'THA': {
        return (
          <>
            <InputContainer>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder isNotBottomBorder>
                    계좌 정보
                  </AccountLabel>
                  <MethodInputContainer disabled={!isEdit}>
                    <Select
                      {...register('bank')}
                      isFullWidth
                      isChnBank
                      isNotBorder
                      isSelected={Boolean(chnBank)}
                      onChange={(e) => setChnBank(e.target.value)}
                    >
                      <Option value='' hidden>
                        은행 선택
                      </Option>
                      {ThbBankList.map((bank, idx) => (
                        <Option key={idx} value={bank}>
                          {bank}
                        </Option>
                      ))}
                    </Select>
                    <AccountInput
                      {...register('bankAccount')}
                      placeholder='계좌번호 입력'
                      disabled={!isEdit}
                    />
                  </MethodInputContainer>
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      case 'AUS': {
        return (
          <>
            <InputContainer>
              <Line>
                <ContentContainer>
                  <AccountLabel>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                    isBottomBorder
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder>은행 계좌</AccountLabel>
                  <MethodInputContainer disabled={!isEdit}>
                    <Select
                      {...register('bank')}
                      isFullWidth
                      isChnBank
                      isNotBorder
                      isSelected={Boolean(chnBank)}
                      onChange={(e) => setChnBank(e.target.value)}
                    >
                      <Option value='' hidden>
                        은행 선택
                      </Option>
                      {AusBankList.map((bank, idx) => (
                        <Option key={idx} value={bank}>
                          {bank}
                        </Option>
                      ))}
                    </Select>
                    <AccountInput
                      {...register('bankAccount')}
                      placeholder='계좌번호 입력'
                      disabled={!isEdit}
                    />
                  </MethodInputContainer>
                </ContentContainer>
              </Line>
              <Line>
                <ContentContainer>
                  <AccountLabel>도시</AccountLabel>
                  <Input
                    {...register('city')}
                    placeholder='도시명 입력 (영문만 가능)'
                    disabled={!isEdit}
                    isBottomBorder
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder>주 / 지방 / 지역</AccountLabel>
                  <Input
                    {...register('stateProvinceRegion')}
                    placeholder='주 / 지방 / 지역명 입력 (영문만 가능)'
                    disabled={!isEdit}
                    isBottomBorder
                  />
                </ContentContainer>
              </Line>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>
                    주 / 지방 / 지역
                  </AccountLabel>
                  <Input
                    {...register('zipPostalCode')}
                    placeholder='우편번호'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder>BSB 번호</AccountLabel>
                  <Input
                    {...register('bsbNumber')}
                    placeholder='BSB 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      case 'CAN': {
        return (
          <>
            <InputContainer>
              <Line>
                <ContentContainer>
                  <AccountLabel>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                    isBottomBorder
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder>은행 계좌</AccountLabel>
                  <MethodInputContainer disabled={!isEdit}>
                    <Select
                      {...register('bank')}
                      isFullWidth
                      isChnBank
                      isNotBorder
                      isSelected={Boolean(chnBank)}
                      onChange={(e) => setChnBank(e.target.value)}
                    >
                      <Option value='' hidden>
                        은행 선택
                      </Option>
                      {CanBankList.map((bank, idx) => (
                        <Option key={idx} value={bank}>
                          {bank}
                        </Option>
                      ))}
                    </Select>
                    <AccountInput
                      {...register('bankAccount')}
                      placeholder='계좌번호 입력'
                      disabled={!isEdit}
                    />
                  </MethodInputContainer>
                </ContentContainer>
              </Line>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>
                    주 / 지방 / 지역
                  </AccountLabel>
                  <Input
                    {...register('stateProvinceRegion')}
                    placeholder='주 / 지방 / 지역명 입력 (영문만 가능)'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder isNotBottomBorder>
                    Transit 번호
                  </AccountLabel>
                  <Input
                    {...register('transitNumber')}
                    placeholder='Transit 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      case 'TWN': {
        return (
          <>
            <InputContainer>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder isNotBottomBorder>
                    캐시 픽업
                  </AccountLabel>
                  <Input
                    {...register('cashPickup')}
                    value='Western Union'
                    disabled={!isEdit}
                    readOnly
                  />
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      case 'IDN': {
        return (
          <>
            <InputContainer>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder isNotBottomBorder>
                    계좌 정보
                  </AccountLabel>
                  <MethodInputContainer disabled={!isEdit}>
                    <Select
                      {...register('bank')}
                      isFullWidth
                      isChnBank
                      isNotBorder
                      isSelected={Boolean(chnBank)}
                      onChange={(e) => setChnBank(e.target.value)}
                    >
                      <Option value='' hidden>
                        은행 선택
                      </Option>
                      {IdnBankList.map((bank, idx) => (
                        <Option key={idx} value={bank}>
                          {bank}
                        </Option>
                      ))}
                    </Select>
                    <AccountInput
                      {...register('bankAccount')}
                      placeholder='계좌번호 입력'
                      disabled={!isEdit}
                    />
                  </MethodInputContainer>
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      case 'SGP': {
        return (
          <>
            <InputContainer>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder isNotBottomBorder>
                    계좌 정보
                  </AccountLabel>
                  <MethodInputContainer disabled={!isEdit}>
                    <Select
                      {...register('bank')}
                      isFullWidth
                      isChnBank
                      isNotBorder
                      isSelected={Boolean(chnBank)}
                      onChange={(e) => setChnBank(e.target.value)}
                    >
                      <Option value='' hidden>
                        은행 선택
                      </Option>
                      {SgpBankList.map((bank, idx) => (
                        <Option key={idx} value={bank}>
                          {bank}
                        </Option>
                      ))}
                    </Select>
                    <AccountInput
                      {...register('bankAccount')}
                      placeholder='계좌번호 입력'
                      disabled={!isEdit}
                    />
                  </MethodInputContainer>
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      case 'ARE': {
        return (
          <>
            <InputContainer>
              <Line isNotBottomBorder>
                <ContentContainer>
                  <AccountLabel isNotBottomBorder>휴대전화</AccountLabel>
                  <Input
                    {...register('phoneNumber')}
                    placeholder='휴대전화 번호 입력'
                    disabled={!isEdit}
                  />
                </ContentContainer>
                <ContentContainer>
                  <AccountLabel isLeftBorder isNotBottomBorder>
                    캐시 픽업
                  </AccountLabel>
                  <Input
                    {...register('cashPickup')}
                    value='Western Union'
                    readOnly
                    disabled={!isEdit}
                  />
                </ContentContainer>
              </Line>
            </InputContainer>
          </>
        );
      }
      default:
        return <></>;
    }
  };
  return (
    <Container>
      <FlexWrap>
        <Typography size='18px' lineHeight='26px' fontWeight='500'>
          환급계좌 정보
        </Typography>
      </FlexWrap>
      {setNationAccount()}
    </Container>
  );
}

const Container = styled.div``;
const InputContainer = styled.div`
  margin-top: 12px;
  border-top: 2px solid #cbccce;
  border-bottom: 2px solid #cbccce;
`;
const Line = styled(FlexWrap)<{ isNotBottomBorder?: boolean }>`
  align-items: center;
  border-bottom: ${(props) =>
    props.isNotBottomBorder ? 'none' : '1px solid #cbccce'};
`;
const ContentContainer = styled(FlexWrap)`
  position: relative;
  align-items: center;
  flex: 1;
`;
const AccountLabel = styled.label<{
  isLeftBorder?: boolean;
  isNotBottomBorder?: boolean;
}>`
  width: 180px;
  height: 50px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 12px 20px;
  background-color: #eaf0fa;
  /* border-bottom: ${(props) =>
    props.isNotBottomBorder ? 'none' : '1px solid #cbccce'}; */
  border-right: 1px solid #cbccce;
  border-left: ${(props) =>
    props.isLeftBorder ? '1px solid #cbccce;' : 'none'};
`;
const MethodContainer = styled(FlexWrap)<{ disabled: boolean }>`
  width: 100%;
  flex: 1;
  padding: 12px 20px;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
      pointer-events: none;
      background-color: #f8fafc;
    `}
`;
const MethodSelect = styled.div<{ isSelectedMethod?: boolean }>`
  color: ${(props) => (props.isSelectedMethod ? '#030303' : '#cbccce')};
  margin-right: 8px;
`;
const Input = styled.input<{
  border?: boolean;
  isBottomBorder?: boolean;
  isNotflex?: boolean;
}>`
  flex: ${(props) => (props.isNotflex ? 'none' : 1)};
  width: 100%;
  height: 50px;
  padding: 12px 20px;
  border-radius: 4px;
  border: none;
  ${(props) =>
    props.border &&
    css`
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      height: 50px;
      border: 1px solid #cbccce;
    `}
  ::placeholder {
    color: #cbccce;
  }
  :disabled {
    background-color: #f8fafc;
  }
`;
const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 376px;
  min-height: 234px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
`;
const BackDrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbccce;
`;
const TitleContainer = styled(FlexWrap)`
  align-items: center;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
const Label = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;
const RequiredDot = styled.div`
  color: #f13e4b;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 4px;
  padding-bottom: 6px;
`;
const Select = styled.select<{
  isArrow?: boolean;
  isChnBank?: boolean;
  isSelected?: boolean;
  isNotBorder?: boolean;
  isFullWidth?: boolean;
}>`
  position: relative;
  width: ${(props) => (!props.isFullWidth ? '100%' : '100px')};
  height: 50px;
  appearance: ${(props) => props.isArrow && 'none'};
  border: ${(props) => (props.isNotBorder ? 'none' : '1px solid #cbccce')};
  padding: 12px 20px;
  padding-right: ${(props) => !props.isArrow && '0'};
  border-radius: 4px;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  cursor: pointer;

  color: ${(props) => (props.isSelected ? '3A3B3E' : '#CBCCCE')};
  ${(props) =>
    props.isNotBorder &&
    css`
      border: none;
      /* border-bottom: 1px solid #cbccce; */
    `}
`;
const Option = styled.option``;
const LabelContainer = styled(FlexWrap)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
`;
const ButtonContainer = styled(FlexWrap)`
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
`;
const Button = styled.button`
  width: 100px;
  height: 42px;
  background-color: #0b42ad;
  color: #fff;
  border-radius: 2px;
`;
const MethodInputContainer = styled(FlexWrap)<{
  disabled: boolean;
  isChn?: boolean;
}>`
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
  ${(props) =>
    props.isChn &&
    css`
      align-items: flex-start;
      flex-direction: column;
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
      pointer-events: none;
      background-color: #f8fafc;
      select {
        background-color: #f8fafc;
      }
    `}
`;
const RefundMethodContainer = styled(FlexWrap)<{ disabled?: boolean }>`
  align-items: center;
  padding: 12px 20px;
  flex: 1;
  gap: 20px;
  background-color: ${(props) => props.disabled && '#f8fafc'};
`;
const StyledRadio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #cbccce;
  cursor: pointer;
  :disabled {
    cursor: default;
    background-color: #f8fafc;
  }
  :checked {
    border: 4px solid #354eff;
    background-color: #ffffff;
  }
`;
const AccountInput = styled.input`
  flex: 1;
  border: none;
  margin-left: 12px;
  ::placeholder {
    color: #cbccce;
  }
  :disabled {
    background-color: #f8fafc;
  }
`;
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;
const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
export default AccountInfo;
