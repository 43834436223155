import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { SideNavigationInfo } from '@/constants/sideNavigation';
import SideMenuItem from './SideMenuItem';
import Naviagtion from '../navigation';
import { logOut } from '@/api/user';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { loginUserState } from '@/store/modules/user';
import defaultProfileIcon from '@/assets/sideBar/defaultProfile.png';

function SideNavigation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = localStorage.getItem('name');
  const profileImg = localStorage.getItem('profileImg');

  const { refetch } = useQuery<null, AxiosError>('logOut', logOut, {
    enabled: false,
    retry: false,
    onSuccess: () => {
      localStorage.setItem('loginToken', '');
      dispatch(loginUserState(false));
      navigate('/');
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const onLogOut = () => {
    refetch();
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('profileImg');
  };

  return (
    <Container>
      <ContentContainer>
        <Naviagtion />
        <Profile>
          <ProfileImage
            src='https://ktp-image.s3.ap-northeast-2.amazonaws.com/medical/27/hospitalImage'
            alt='프로필.'
            width='48px'
            height='48px'
          />
          <ProfileContent>
            안녕하세요.
            <br />
            <Bold>{name}</Bold> 님
          </ProfileContent>
        </Profile>
        <List>
          {SideNavigationInfo.map((item) => (
            <Link to={item.link} key={item.id}>
              <SideMenuItem menuItem={item} />
            </Link>
          ))}
        </List>
      </ContentContainer>
      <LogOutBtn onClick={onLogOut}>
        <span>로그아웃</span>
      </LogOutBtn>
    </Container>
  );
}

const Container = styled.div`
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 0px;
  min-width: 280px;
  height: calc(100vh);
  overflow: auto;
  background-color: #0b42ad;
  z-index: 10;
  @media ${(props) => props.theme.laptop} {
    min-width: 200px;
  }
`;
const ContentContainer = styled.div``;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LogOutBtn = styled.button`
  /* position: absolute; */
  /* bottom: 40px; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  padding: 10px 0px;
  margin: 20px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border: 2px solid #c7e0f1;
  border-radius: 2px;
  color: #c7e0f1;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 40px 20px;
  @media ${(props) => props.theme.laptop} {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 40px;
  }
`;

const ProfileImage = styled.img`
  object-fit: contain;
`;

const ProfileContent = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) => props.theme.pointColors.white};
`;

const Bold = styled.span`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

export default SideNavigation;
