import React from 'react';
import styled from 'styled-components';

import { UseFormRegister } from 'react-hook-form';
import FlexWrap from '../common/FlexWrap';
import Typography from '../common/Typography';
import { AccountInputFormType } from './DetailModal';

type RefundMemoProps = {
  isEdit: boolean;
  register: UseFormRegister<AccountInputFormType>;
};

function RefundMemo({ isEdit, register }: RefundMemoProps) {
  return (
    <Container>
      <FlexWrap>
        <Typography
          size='18px'
          lineHeight='26px'
          fontWeight='500'
          margin='0 0 16px 0'
        >
          메모
        </Typography>
      </FlexWrap>
      <TextArea
        {...register('memo')}
        placeholder='작성된 메모가 없습니다.'
        disabled={!isEdit}
      />
    </Container>
  );
}
const Container = styled.div``;
const TextArea = styled.textarea`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  width: 100%;
  min-height: 80px;
  padding: 20px;
  border: 1px solid #cbccce;
  border-radius: 4px;
  resize: none;
  ::placeholder {
    color: #cbccce;
  }
  :disabled {
    background-color: #f8fafc;
  }
`;
export default RefundMemo;
