import { routes } from '@/routes';
import homeIcon from '@/assets/sideBar/home.png';
import homeIconActive from '@/assets/sideBar/home_active.png';
import refundIcon from '@/assets/sideBar/refund.png';
import refundActiveIcon from '@/assets/sideBar/refund_active.png';
import refundDetailIcon from '@/assets/sideBar/refund_detail.png';
import refundDetailIconActive from '@/assets/sideBar/refund_detail_active.png';
import medicalIcon from '@/assets/sideBar/medical.png';
import medicalIconActive from '@/assets/sideBar/medical_active.png';
import noticeIcon from '@/assets/sideBar/notice.png';
import noticeIconActive from '@/assets/sideBar/notice_active.png';

export const SideNavigationInfo = [
  {
    id: 1,
    title: '홈',
    uri: { active: homeIconActive, notActive: homeIcon },
    link: routes.home,
  },
  {
    id: 2,
    title: '환급하기',
    uri: { active: refundActiveIcon, notActive: refundIcon },
    link: routes.refund,
  },
  {
    id: 3,
    title: '환급내역',
    uri: { active: refundDetailIconActive, notActive: refundDetailIcon },
    link: routes.refundDetail,
  },
  {
    id: 4,
    title: '유치기관',
    uri: { active: medicalIconActive, notActive: medicalIcon },
    link: routes.medicalInfo,
  },
  {
    id: 5,
    title: '공지사항',
    uri: { active: noticeIconActive, notActive: noticeIcon },
    link: routes.notice,
  },
];
