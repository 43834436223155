import React from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import foreignerIcon from '@/assets/Home/foreigner.png';
import paymentIcon from '@/assets/Home/payment.png';
import refundIcon from '@/assets/Home/refund.png';
import { IHomeCalendarResponae } from '@/types/home';
import { comma } from '@/util/format';
import { useAppSelecotr } from '@/hooks/useReduxHooks';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

function MedicalRefundInfo({ medicalInfo }: IProps) {
  const { refundSummary } = medicalInfo;
  const { isAutoPayment } = useAppSelecotr((state) => state.user);

  return (
    <Container>
      <RefundItem>
        <Logo src={foreignerIcon} />
        <Content>
          <Text isTitle>외국인 관광객 유치 수</Text>
          <Text>
            <Bold>{refundSummary.totalCount}</Bold> 명
          </Text>
        </Content>
      </RefundItem>
      <RefundItem>
        <Logo src={paymentIcon} />
        <Content>
          <Text isTitle>결제금액</Text>
          <Text>
            <Bold>
              {comma(refundSummary.totalPrice + refundSummary.totalRefund)}
            </Bold>
            원
          </Text>
        </Content>
      </RefundItem>
      <RefundItem>
        <Logo src={refundIcon} />
        <Content>
          <Text isTitle>{isAutoPayment ? '환급액' : '누적 예상환급액'}</Text>
          <Text>
            <Bold>{comma(refundSummary.totalRefund)}</Bold> 원
          </Text>
        </Content>
      </RefundItem>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${(props) => props.theme.laptop} {
    gap: 15px;
  }
`;

const RefundItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 56px;
  width: 33%;
  height: 180px;
  padding: 30px 20px;
  border-radius: 10px;
  filter: ${(props) => props.theme.boxShadow};
  background-color: ${(props) => props.theme.pointColors.white};
  @media ${(props) => props.theme.laptop} {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 20px 16px;
  }
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  @media ${(props) => props.theme.laptop} {
    width: 68px;
    height: 68px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const Text = styled.div<{ isTitle?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  align-self: ${(props) => (props.isTitle ? 'flex-start' : 'flex-end')};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
    align-self: center;
  }
`;

const Bold = styled.span`
  font-size: 42px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  color: #0b42ad;
  @media ${(props) => props.theme.laptop} {
    font-size: 28px;
    line-height: ${(props) => props.theme.lineHeight.xLarge};
  }
`;

export default MedicalRefundInfo;
