import FlexWrap from '@/components/common/FlexWrap';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { printValidateState } from '@/store/modules/validate';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import PrintElement from '../PrintElement';

interface IProps {
  touristIndex: number;
  refundIndex: number;
  setIsPreview: Dispatch<SetStateAction<boolean>>;
}

function RefundPrint({ touristIndex, refundIndex, setIsPreview }: IProps) {
  const printRef = useRef(null);
  const dispatch = useAppDispatch();

  const onClickPrint = () => {
    dispatch(printValidateState());
  };

  return (
    <Container>
      <FlexWrap alignItems='center' gap='12px'>
        <div onClick={onClickPrint}>
          <ReactToPrint
            trigger={() => <PrintBtn>출력</PrintBtn>}
            content={() => printRef.current}
          />
        </div>
        <PreviewBtn onClick={() => setIsPreview(true)}>미리보기</PreviewBtn>
      </FlexWrap>
      <PreviewSection>
        <div ref={printRef}>
          <PrintElement touristIndex={touristIndex} refundIndex={refundIndex} />
        </div>
      </PreviewSection>
    </Container>
  );
}

const Container = styled.div`
  margin-left: 47px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const PrintBtn = styled.button`
  padding: 13px 15px;
  border-radius: 2px;
  width: 100px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.pointColors.white};
  background-color: #212121;
`;

const PreviewBtn = styled.button`
  width: 100px;
  padding: 13px 15px;
  border-radius: 2px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.grayColors.scale1};
  border: 1px solid ${(props) => props.theme.grayColors.scale2};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const PreviewSection = styled.div`
  display: none; // 추후에 미리보기 구현시 수정
  height: 500px;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.pointColors.white};
`;

export default RefundPrint;
