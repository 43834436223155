import { memo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';

import Calendar from '@/assets/refund/calendar.png';
import { addMonths, format } from 'date-fns';

function DateSelector({
  setValue,
  isActiveCalendar,
  setIsActiveCalendar,
}: any) {
  const currentDate = new Date();
  const threeMonthsAgo = addMonths(currentDate, -3);

  const onChangeDate = (date: any) => {
    setIsActiveCalendar(false);
    setValue('supplyDate', format(date, 'yyyy-MM-dd'));
  };

  return (
    <>
      {!isActiveCalendar && (
        <DateSelectorBtn onMouseDown={() => setIsActiveCalendar(true)}>
          <Icon src={Calendar} />
        </DateSelectorBtn>
      )}
      {isActiveCalendar && (
        <>
          <CustomDatePicker
            dateFormat='yyyy.MM.dd'
            shouldCloseOnSelect
            minDate={threeMonthsAgo}
            maxDate={currentDate}
            inline
            onChange={onChangeDate}
            onClickOutside={() => setIsActiveCalendar(false)}
          />
        </>
      )}
    </>
  );
}

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
const DateSelectorBtn = styled.button`
  display: flex;
  padding-right: 12px;
`;

const CustomDatePicker = styled(DatePicker)`
  z-index: 1000; /* Updated z-index value */
  background-color: #fff;
`;

export default memo(DateSelector);
