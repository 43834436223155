import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import { homeCalendar } from '@/api/home';
import { paymentTypeState } from '@/store/modules/user';
import { IHomeCalendarPayload, IHomeCalendarResponae } from '@/types/home';
import { useAppDispatch, useAppSelecotr } from './useReduxHooks';

export const useHomeCalendar = () => {
  const { startDate, endDate } = useAppSelecotr((state) => state.date.filter);
  const dispatch = useAppDispatch();
  const params = {
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  };

  const { refetch, data, isLoading } = useQuery<
    IHomeCalendarPayload,
    AxiosError,
    IHomeCalendarResponae
  >(['home', startDate, endDate], () => homeCalendar(params), {
    retry: false,
    onSuccess: (res) => {
      dispatch(paymentTypeState(res.paymentType === 'ORIGINAL'));
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return { refetch, data, isLoading };
};
