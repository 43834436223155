import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import Icon from '../common/Icon';
import Typography from '../common/Typography';
import cancleIcon from '@/assets/common/cancle.png';

type DetailModalHeaderProps = {
  setIsModal: Dispatch<SetStateAction<boolean>>;
};

function DetailModalHeader({ setIsModal }: DetailModalHeaderProps) {
  return (
    <Container>
      <Typography size='21px' lineHeight='25px' fontWeight='700'>
        환급내역 상세보기
      </Typography>
      <Button onClick={() => setIsModal(false)}>
        <Icon imgUrl={cancleIcon} width='18px' height='18px' />
      </Button>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e5e6e8;
`;
const Button = styled.button``;
export default DetailModalHeader;
