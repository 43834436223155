import React from 'react';
import styled from 'styled-components';

type IconProps = {
  width: string;
  height: string;
  imgUrl: string;
  margin?: string;
  cursor?: string;
  onClick?: () => void;
};

function Icon({ width, height, imgUrl, margin, cursor, onClick }: IconProps) {
  return (
    <Image
      src={imgUrl}
      width={width}
      height={height}
      margin={margin}
      cursor={cursor}
      onClick={onClick}
    />
  );
}

const Image = styled.img<{ margin?: string; cursor?: string }>`
  object-fit: contain;
  margin: ${(props) => props.margin || '0px'};
  cursor: ${(props) => props.cursor};
`;

export default Icon;
