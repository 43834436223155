import html2canvas from 'html2canvas';

export const convertComponentToImage = async (el: HTMLElement) => {
  const canvas = await html2canvas(el);
  return dataURItoBlob(canvas.toDataURL('image/png', 1.0));
};
export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const refundDetailText = (refundStatus: string, isTransfer: boolean) => {
  switch (refundStatus) {
    case 'APPROVE_CONFIRM':
      return isTransfer ? '송금 완료' : '송금 미완료';
    case 'CANCEL':
      return '환급 취소';
    default:
      return '-';
  }
};

export const refundDetailColor = (
  refundStatus: string,
  isTransfer: boolean
) => {
  switch (refundStatus) {
    case 'APPROVE_CONFIRM':
      return isTransfer ? '#0B42AD' : '#ED0828';
    case 'CANCEL':
      return '#80848A';
    default:
      return '-';
  }
};

export function truncateString(str: string, maxLength: number) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
}
