import { createGlobalStyle, DefaultTheme } from 'styled-components';
import reset from 'styled-reset';

export const theme: DefaultTheme = {
  fontSize: {
    xLarge: '30px',
    large: '24px',
    medium: '20px',
    regular: '18px',
    small: '16px',
    xSmall: '14px',
  },
  fontWeight: {
    bold: 700,
    medium: 500,
    regular: 400,
  },
  lineHeight: {
    xLarge: '38px',
    large: '32px',
    medium: '28px',
    regular: '26px',
    small: '24px',
    xSmall: '22px',
  },
  priamryColors: {
    primary: '#00658C',
    primaryVarient: '#E2F3FF',
    error: '#E42939',
  },
  pointColors: {
    white: '#FFFFFF',
  },
  grayColors: {
    scale1: '#212121',
    scale2: '#757575',
    scale3: '#BDBDBD',
    scale4: '#E0E0E0',
    scale5: '#F5F5F5',
  },
  blueColors: {
    scale1: '#DADEE4',
    scale2: '#EAEFF3',
    scale3: '#F7F9FC',
    scale4: '#A9C8DD',
  },
  boxShadow: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.14))',
  laptop: '(max-width: 1800px)',
};

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
      box-sizing: border-box;
      font-family: 'Noto Scans KR';
      font-weight: ${(props) => props.theme.fontWeight.regular};
      line-height: ${(props) => props.theme.lineHeight.xSmall};
      font-size: ${(props) => props.theme.fontSize.xSmall};
      :focus{
        outline: 0;
      }
  }

  body {
    font-family: 'Noto Scans KR';
      font-weight: ${(props) => props.theme.fontWeight.regular};
      line-height: ${(props) => props.theme.lineHeight.xSmall};
      font-size: ${(props) => props.theme.fontSize.xSmall};
      min-width: 1024px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.grayColors.scale1};
  }

  img {
    object-fit: contain;
  }

  button {
    cursor: pointer;
    border:none;
    background-color:transparent;
    outline:none;
    :disabled {
      cursor: auto;
    }
  }

  input:-webkit-autofill {
	  -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
  }
  .react-datepicker__month-container{
    background-color: #FFF;
  }
`;
