import React from 'react';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Logo from '@/assets/common/logo.png';
import { guideContentData, procedureData } from '@/constants/print';

function ServiceGuide() {
  return (
    <Container>
      <TopSection justifyContent='space-between'>
        <Header>
          {`KTP(Korea Tax Free Payments)\nMedical Service VAT Refund Service Guide`}
        </Header>
        <Icon src={Logo} />
      </TopSection>
      {guideContentData.map((item, idx) => (
        <ContentContainer key={idx}>
          <Title>{item.title}</Title>
          {item.content && <Content>{item.content}</Content>}
        </ContentContainer>
      ))}
      <ProcedureBox dir='column' alignItems='center'>
        <Title fontSize='32px'>{`< TAX REFUND Procedure >`}</Title>
        {procedureData.map((item, idx) => (
          <ProcedureItem key={idx} dir='column'>
            <BoldContent>{item.title}</BoldContent>
            {item.underline && <Content underline>{item.underline}</Content>}
            {item.content && <Content>{item.content}</Content>}
          </ProcedureItem>
        ))}
      </ProcedureBox>
      <Content>
        {`- The information of the patient (customer) written in [Document 1] must
match the information of your passport.
        ※ If the passport information is different, it may be difficult to proceed with the refund.`}
      </Content>
      <BottomText>
        If you have more questions about your tax refund, you can contact
        ‘ktp@successmode.kr’
      </BottomText>
    </Container>
  );
}
const Container = styled.div`
  height: 1680px;
  padding: 70px;
  width: 100%;
`;
const TopSection = styled(FlexWrap)`
  background-color: #1b84a2;
  padding: 20px 40px;
  width: 100%;
  margin-bottom: 30px;
`;
const Header = styled.h1`
  color: #ffffff;
  font-size: 36px;
  line-height: 1.5;
  font-weight: 700;
  white-space: pre-line;
`;
const Icon = styled.img`
  width: 120px;
`;
const Title = styled.h2<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize || '24px'};
  padding-left: 12px;
  margin: 40px 0 30px;
  font-weight: bold;
`;
const ContentContainer = styled.div``;
const Content = styled.p<{ marginBottom?: string; underline?: boolean }>`
  font-size: 18px;
  line-height: 2;
  margin-bottom: ${(props) => props.marginBottom || 0};
  white-space: pre-wrap;
  padding-left: 30px;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  width: 100%;
`;
const BoldContent = styled.p<{ marginBottom?: string }>`
  width: 100%;
  font-size: 20px;
  line-height: 1.6;
  text-indent: -35px;
  padding-left: 30px;
  font-weight: 700;
  margin-bottom: ${(props) => props.marginBottom || 0};
  white-space: pre-wrap;
`;
const ProcedureBox = styled(FlexWrap)`
  border: 2px solid gray;
  padding: 0 60px 30px;
  width: 100%;
  margin-bottom: 20px;
`;
const ProcedureItem = styled(FlexWrap)`
  margin-bottom: 20px;
  width: 100%;
`;
const BottomText = styled.p`
  font-size: 18px;
  margin-top: 60px;
  text-align: center;
  width: 100%;
  border-top: 1px solid #000000;
  padding-top: 20px;
  color: #757575;
`;
export default ServiceGuide;
