import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  memo: '',
  productAllDto: [],
};

const productSlice = createSlice({
  name: 'validate',
  initialState,
  reducers: {
    validateReset: () => initialState,
    setProductAllDto: (state, action) => {
      state.productAllDto = action.payload;
    },
    setMemo: (state, action) => {
      state.memo = action.payload;
    },
  },
});

export const { validateReset, setProductAllDto, setMemo} = productSlice.actions;
export default productSlice.reducer;
