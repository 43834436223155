import { getRefundDetailData } from '@/api/refund';
import { IRefundDetailResponse } from '@/types/refund';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useAppSelecotr } from './useReduxHooks';

export const useRefndDetail = (page: number = 0) => {
  const [totalPage, setTotalPage] = useState(0);
  const { refundDetail, searchType, value, size, refundStatus } =
    useAppSelecotr((state) => state.date);
  const { startDate, endDate } = refundDetail;

  const params = {
    page,
    size,
    searchType,
    value,
    refundStatus,
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  };

  const { data, refetch } = useQuery<IRefundDetailResponse, AxiosError>(
    ['refundDetail', params],
    async () => await getRefundDetailData(params),
    {
      onSuccess: (res) => {
        setTotalPage(res.totalPages);
      },
      retry: false,
    }
  );

  return { data, totalPage, refetch };
};
