import React, { Dispatch, SetStateAction, useState } from 'react';
import { QueryClient, useMutation } from 'react-query';
import styled from 'styled-components';
import { AxiosError } from 'axios';

import { refundCancle } from '@/api/refund';
import { IRefundCanclePayload } from '@/types/refund';
import FlexWrap from '../common/FlexWrap';
import Modal from '../common/Modal';
import Typography from '../common/Typography';
import { useRefndDetail } from '@/hooks/useRefundDetail';
import { useFormContext } from 'react-hook-form';

type BottomContentProps = {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  refundStatus: string;
  isTransfer: boolean;
  refundIndex: number;
  onSubmit: any;
  handleSubmit: any;
};

function BottomContent({
  isEdit,
  setIsEdit,
  setIsModal,
  refundStatus,
  isTransfer,
  refundIndex,
  onSubmit,
  handleSubmit,
}: BottomContentProps) {
  const [isDetailModal, setIsDetailModal] = useState(false);
  const isDisable = refundStatus === 'CANCEL';
  const isCancle = refundStatus === 'CANCEL';
  const { refetch } = useRefndDetail();

  const { mutate: refundMutation } = useMutation<
    null,
    AxiosError,
    IRefundCanclePayload
  >(['refundCancle'], (payload) => refundCancle(payload), {
    onSuccess: () => {
      refetch();
      setIsDetailModal(false);
    },
  });

  const onClick = () => {
    // if (refundStatus === 'CANCEL') {
    //   setIsModal(false);
    // } else if (isEdit) {
    //   setIsEdit(false);
    // } else {
    //   setIsEdit(true);
    // }
    if (isEdit) {
      setIsEdit(false);
      handleSubmit(onSubmit);
    } else {
      setIsEdit(true);
    }
  };

  const onClickRefundCancle = () => {
    const payload = { cancelList: [refundIndex] };
    refundMutation(payload);
  };

  return (
    <Container>
      <Typography
        size='16px'
        lineHeight='24px'
        fontWeight='400'
        color='#ED0828'
      >
        공급일로부터 3개월 외는 수정이 불가능합니다.
      </Typography>
      <ButtonWrapper>
        <Button
          isCancleBtn
          disabled={isCancle}
          onClick={() => setIsDetailModal(true)}
        >
          환급 취소
        </Button>
        <Button className='editBtn' isEdit={isEdit} onClick={onClick}>
          {isEdit ? '수정완료' : '수정'}
        </Button>
      </ButtonWrapper>
      {isDetailModal && (
        <Modal
          title='환급 취소'
          content={
            isTransfer
              ? '송금이 완료되어 환급 취소가 불가합니다\n고객센터(02-6275-8011)로 문의주세요.'
              : '환급 취소하시겠습니까?'
          }
          onCancle={() => setIsDetailModal(false)}
          onComfirm={onClickRefundCancle}
          isAlert={isTransfer}
        />
      )}
    </Container>
  );
}

const Container = styled(FlexWrap)`
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  flex: 1;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const Button = styled.button<{ isEdit?: boolean; isCancleBtn?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  width: 100px;
  height: 42px;
  color: ${(props) => (props.isCancleBtn ? '#246cf6' : '#FFF')};
  border-radius: 2px;
  background-color: ${(props) => (props.isCancleBtn ? '#FFF' : '#246cf6')};
  border: ${(props) => props.isCancleBtn && '2px solid #246cf6'};
  :disabled {
    border: 2px solid #cbccce;
    color: #cbccce;
  }
`;
export default BottomContent;
