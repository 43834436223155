import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { hospitalFind } from '@/api/refund';
import arrowDownIcon from '@/assets/common/arrowDown.png';
import { IhospitalFindResponse } from '@/types/refund';
import FlexWrap from '../common/FlexWrap';
import Typography from '../common/Typography';

function HospitalSelect() {
  const [hospitalList, setHospitalList] = useState<string[]>([]);
  const [hospitalCurrList, setHospitalCurrList] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isToggle, setIsToggle] = useState(false);
  const methods = useFormContext();

  const { data } = useQuery<IhospitalFindResponse[], null>(
    'hospitalNames',
    hospitalFind,
    {
      onSuccess: (res) => {
        const newArray: string[] = [];
        res.forEach((item) => {
          newArray.push(item.name);
        });
        setHospitalList(newArray);
      },
    }
  );

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value.toLocaleLowerCase();
    const newArray: string[] = [];

    hospitalList.forEach((item) => {
      if (item.toLocaleLowerCase().includes(target) && target !== '') {
        newArray.push(item);
      }
    });
    let newTest;
    data?.forEach((item) => {
      if (item.name === e.target.value) {
        newTest = item.hospitalIndex;
      }
    });
    methods.setValue('hospitalIndex', newTest);
    setSearchKeyword(target);
    setIsToggle(true);
    setHospitalCurrList(newArray);
  };

  const onClickKeyword = (hospital: string) => {
    data?.forEach((item) => {
      if (item.name === hospital) {
        methods.setValue('hospitalIndex', item.hospitalIndex);
      }
    });
    setSearchKeyword(hospital);
    setIsToggle(false);
  };
  const onClickToggleList = () => {
    if (isToggle) {
      setIsToggle(false);
    } else {
      setIsToggle(true);
      setHospitalCurrList(hospitalList);
    }
  };
  const keywordColorSet = (value: string) => {
    return (
      <>
        {value.split('').map((item: string) => {
          if (searchKeyword.includes(item)) {
            return <span style={{ color: 'red' }}>{item}</span>;
          } else {
            return <span>{item}</span>;
          }
        })}
      </>
    );
  };
  return (
    <Container>
      <Typography size='20px' lineHeight='28px' fontWeight='500'>
        병원 선택
      </Typography>
      <SearchContainer>
        <Typography>병원명</Typography>
        <InputContainer onSubmit={(e) => e.preventDefault()}>
          <Label htmlFor='serach'>
            <ArrowIcon src={arrowDownIcon} />
          </Label>
          <SearchInput
            id='serach'
            name='serach'
            placeholder='병원명을 입력해주세요'
            onChange={onChangeSearch}
            onFocus={onClickToggleList}
            value={searchKeyword}
            onBlur={() => setIsToggle(false)}
            autoComplete='off'
            isSelect={methods.getValues('hospitalIndex')}
          />
          {isToggle && hospitalCurrList.length > 0 && (
            <KeywordList>
              {hospitalCurrList.map((hospital, idx) => (
                <KeywordItem
                  key={idx}
                  onMouseDown={() => onClickKeyword(hospital)}
                >
                  {keywordColorSet(hospital)}
                </KeywordItem>
              ))}
            </KeywordList>
          )}
        </InputContainer>
      </SearchContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
`;
const SearchContainer = styled(FlexWrap)`
  align-items: center;
  gap: 80px;
  margin-top: 24px;
  padding: 40px 50px;
  border: 1px solid #cbccce;
`;
const InputContainer = styled.form`
  position: relative;
`;
const SearchInput = styled.input<{ isSelect: boolean }>`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  padding: 9px 15px;
  min-width: 480px;
  background-color: #fff;
  border: 1px solid #cbccce;
  border-radius: 2px;
  color: ${(props) => props.isSelect && '#0B42AD'};
  ::placeholder {
    color: #cbccce;
  }
`;
const Label = styled.label`
  top: 50%;
  position: absolute;
  right: 10px;
`;
const ArrowIcon = styled.img`
  top: 50%;
  cursor: pointer;
  right: 10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
`;
const KeywordList = styled(FlexWrap)`
  flex-direction: column;
  gap: 8px;
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #cbccce;
  border-radius: 2px;
  max-height: 400px;
  overflow: auto;
`;
const KeywordItem = styled.div`
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background-color: #cbccce;
  }
`;
const ErrorMessage = styled.p`
  position: absolute;
  color: ${(props) => props.theme.priamryColors.error};
`;
export default HospitalSelect;
