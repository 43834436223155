import React, { Dispatch, SetStateAction, useState } from 'react';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';

import { touristRefundInfoGet, touristRefundInfoUpdate } from '@/api/refund';
import { IRefundDetailContent } from '@/types/refund';
import LoadingView from '../common/LoadingView';
import AccountInfo from './AccountInfo';
import BottomContent from './BottomContent';
import DetailModalHeader from './DetailModalHeader';
import RefundDocument from './RefundDocument';
import RefundMemo from './RefundMemo';
import RefundOutline from './RefundOutline';

type DetailModalProps = {
  refund: IRefundDetailContent;
  setIsModal: Dispatch<SetStateAction<boolean>>;
};

export type AccoutUpdatePayload = {
  accountInfo: AccountInfoType;
  memo: string | null;
};

export type AccountInfoType = {
  accountInfo: string | null;
  aliPayAccount: string | null;
  bank: string | null;
  bankAccount: string | null;
  branchInformation: string | null;
  bsbNumber: string | null;
  cardNumber: string | null;
  cashPickup: string | null;
  city: string | null;
  phoneNumber: string | null;
  routingNumber: string | null;
  stateProvinceRegion: string | null;
  transferMethod: string | null;
  transitNumber: string | null;
  zipPostalCode: string | null;
};

export type AccountInputFormType = {
  nation: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  email: string | null;
  transferMethod: string | null;
  aliPayAccount: string | null;
  cardNumber: string | null;
  bank: string | null;
  bankAccount: string | null;
  routingNumber: string | null;
  city: string | null;
  stateProvinceRegion: string | null;
  zipPostalCode: string | null;
  branchInformation: string | null;
  bsbNumber: string | null;
  transitNumber: string | null;
  cashPickup: string | null;
  accountInfo: string | null;
  memo: string | null;
};

function DetailModal({ refund, setIsModal }: DetailModalProps) {
  const [isEdit, setIsEdit] = useState(false);

  const { data: touristData, isLoading } = useQuery(
    ['touristInfo'],
    async () => await touristRefundInfoGet(refund.touristIndex),
    {
      onSuccess: (data) => {
        setValue('phoneNumber', data.accountInfo?.phoneNumber);
        setValue('transferMethod', data.accountInfo?.transferMethod);
        setValue('aliPayAccount', data.accountInfo?.aliPayAccount);
        setValue('cardNumber', data.accountInfo?.cardNumber);
        setValue('bank', data.accountInfo?.bank);
        setValue('bankAccount', data.accountInfo?.bankAccount);
        setValue('routingNumber', data.accountInfo?.routingNumber);
        setValue('city', data.accountInfo?.city);
        setValue('stateProvinceRegion', data.accountInfo?.stateProvinceRegion);
        setValue('zipPostalCode', data.accountInfo?.zipPostalCode);
        setValue('branchInformation', data.accountInfo?.branchInformation);
        setValue('bsbNumber', data.accountInfo?.bsbNumber);
        setValue('transitNumber', data.accountInfo?.transitNumber);
        setValue('cashPickup', data.accountInfo?.cashPickup);
        setValue('memo', data.memo);
      },
    }
  );

  const { mutate: touristUpdate } = useMutation<
    null,
    AxiosError,
    AccoutUpdatePayload
  >((payload) => touristRefundInfoUpdate(refund.touristIndex, payload), {
    retry: false,
    onSuccess: () => {},
    onError: (error) => {
      console.log('에러', error);
    },
  });
  const { handleSubmit, register, setValue, getValues } =
    useForm<AccountInputFormType>();

  const onSubmit = (value: AccountInputFormType) => {
    if (isEdit) return;
    const {
      accountInfo,
      aliPayAccount,
      bank,
      bankAccount,
      branchInformation,
      bsbNumber,
      cardNumber,
      cashPickup,
      city,
      phoneNumber,
      routingNumber,
      stateProvinceRegion,
      transferMethod,
      transitNumber,
      zipPostalCode,
    } = value;
    const payload: AccoutUpdatePayload = {
      accountInfo: {
        accountInfo,
        aliPayAccount,
        bank,
        bankAccount,
        branchInformation,
        bsbNumber,
        cardNumber,
        cashPickup,
        city,
        phoneNumber,
        routingNumber,
        stateProvinceRegion,
        transferMethod,
        transitNumber,
        zipPostalCode,
      },
      memo: value.memo,
    };
    touristUpdate(payload);
  };

  return (
    <BackDrop onClick={() => setIsModal(false)}>
      {isLoading ? (
        <LoadingView />
      ) : (
        <Wrapper
          onSubmit={handleSubmit(onSubmit)}
          onClick={(e) => e.stopPropagation()}
        >
          <DetailModalHeader setIsModal={setIsModal} />
          <Container>
            <RefundOutline refund={refund} />
            <RefundMemo isEdit={isEdit} register={register} />
            <AccountInfo
              nation={refund.nation}
              touristData={touristData?.accountInfo}
              getValues={getValues}
              setValue={setValue}
              isEdit={isEdit}
              register={register}
            />
            <RefundDocument refund={refund} />
          </Container>
          <DivideLine />
          <BottomContent
            isEdit={isEdit}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            refundIndex={refund.refundIndex}
            setIsEdit={setIsEdit}
            setIsModal={setIsModal}
            refundStatus={refund.refundStatus}
            isTransfer={refund.isTransfer}
          />
        </Wrapper>
      )}
    </BackDrop>
  );
}

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.4);
  z-index: 10;
  overflow: auto;
`;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70%;
  /* max-height: 70%; */
  overflow: auto;
  border-radius: 12px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e6e8;
  margin-bottom: 24px;
`;

export default DetailModal;
