import React from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

import { IHomeCalendarResponae } from '@/types/home';
import DateSelector from '../common/DateSelectorPeriod';
import MedicalRefundInfo from './MedicalRefundInfo';
import { useAppSelecotr } from '@/hooks/useReduxHooks';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

function HomeHeader({ medicalInfo }: IProps) {
  const { startDate, endDate } = useAppSelecotr((state) => state.date.filter);
  return (
    <>
      <Title>홈</Title>
      <Container>
        <Header>
          <SubTitle>환급현황</SubTitle>
          <DateSelector currStartDate={startDate} currEndDate={endDate} />
        </Header>
        <MedicalRefundInfo medicalInfo={medicalInfo} />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 22px 40px 85px 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  padding: 23px 40px;
  border-bottom: 1px solid #cbccce;
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;

const SubTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

export default HomeHeader;
