import Icon from '@/components/common/Icon';
import React, { useState } from 'react';
import styled from 'styled-components';
import arrowDownIcon from '@/assets/common/arrowDownFull.png';
import Typography from '@/components/common/Typography';
import FlexWrap from '@/components/common/FlexWrap';
import { useDispatch } from 'react-redux';
import { setRefundStatusSeletor } from '@/store/modules/date';

interface IProps {
  titleList: CardGraph[];
}

interface CardGraph {
  id: number;
  title: string;
  flex: number;
  isDrop?: boolean;
}

const dropFilterList = [
  { name: '전체', refundType: '' },
  { name: '환급완료', refundType: 'APPROVE_CONFIRM' },
  { name: '환급미완료', refundType: 'APPROVE_WAIT' },
  { name: '환급취소', refundType: 'CANCEL' },
];

function TableHeader({ titleList }: IProps) {
  const [isDropFilter, setIsDropFilter] = useState(false);
  const [refundStatus, setRefundStatus] = useState('');
  const dispatch = useDispatch();

  const onChangeRefundFilter = (refundType: string) => {
    dispatch(setRefundStatusSeletor(refundType));
    setRefundStatus(refundType);
    setIsDropFilter(false);
  };
  return (
    <Container>
      {titleList.map((card) => (
        <CardTitle key={card.id} flex={card.flex}>
          {card.title}
          {card.isDrop && (
            <Button
              onClick={() => setIsDropFilter(!isDropFilter)}
              isDropFilter={isDropFilter}
            >
              <Icon imgUrl={arrowDownIcon} width='14px' height='8px' />
            </Button>
          )}
          {isDropFilter && card.isDrop && (
            <DropFilter>
              {dropFilterList.map((item) => (
                <>
                  <StyledRadio
                    type='radio'
                    value='MEDIVISOR'
                    onChange={() => onChangeRefundFilter(item.refundType)}
                    checked={refundStatus === item.refundType}
                  />
                  <Typography size='16px' lineHeight='24px' fontWeight='400'>
                    {item.name}
                  </Typography>
                </>
              ))}
            </DropFilter>
          )}
        </CardTitle>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 26px;
  background-color: #eaf0fa;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;
const CardTitle = styled.div<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :last-child {
    border-right: none;
  }
`;
const Button = styled.button<{ isDropFilter: boolean }>`
  transform: ${(props) =>
    props.isDropFilter ? 'rotate(-180deg)' : 'rotate(0)'};
  transition: transform 300ms ease;
`;
const DropFilter = styled(FlexWrap)`
  gap: 8px;
  position: absolute;
  right: 0;
  top: 110%;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 20px;
  box-shadow: 2px 3px 10px lightgray;
`;
const StyledRadio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #cbccce;
  cursor: pointer;
  &::disabled {
    cursor: default;
  }
  &:checked {
    border: 4px solid #354eff;
    background-color: #ffffff;
  }
`;
export default TableHeader;
