import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import resetActiveIcon from '@/assets/common/resetActive.png';
import resetIcon from '@/assets/common/reset.png';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { payCaculate } from '@/store/modules/refund';
import Checkbox from '@/components/common/CheckBox';

interface IProps {
  index: number;
}

function SupplyInputBtn({ index }: IProps) {
  const [isReset, setIsReset] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const watchField = methods.watch([
    `supply.${index}.price`,
    `supply.${index}.productType`,
  ]);

  useEffect(() => {
    if (
      methods.getValues(`supply.${index}.price`) ||
      methods.getValues(`supply.${index}.productType`)
    ) {
      setIsReset(true);
    } else {
      setIsReset(false);
    }

    if (index <= 0) {
      setIsDisable(true);
    }
  }, [watchField]);

  const onReset = () => {
    methods.setValue(`supply.${index}.price`, '');
    methods.setValue(`supply.${index}.productType`, '');
    methods.setValue(`supply.${index}.supplyPrice`, '');
    methods.setValue(`supply.${index}.vatPrice`, '');
    const payload = {
      price: Number(
        methods.getValues(`supply.${index}.price`).replace(/(,)/g, '')
      ),
      supplyPrice: Number(
        methods.getValues(`supply.${index}.supplyPrice`).replace(/(,)/g, '')
      ),
      vatPrice: Number(
        methods.getValues(`supply.${index}.vatPrice`).replace(/(,)/g, '')
      ),
      index,
    };
    dispatch(payCaculate(payload));
  };

  const onClick = () => {
    setIsCheck((prev) => !prev);
  };

  useEffect(() => {
    methods.setValue(`supply.${index}.isCheck`, isCheck);
  }, [isCheck]);

  return (
    <>
      <Item flex={1.5}>
        <ResetButton isReset={isReset} onClick={onReset}>
          <Image src={isReset ? resetActiveIcon : resetIcon} />
          <Text>초기화</Text>
        </ResetButton>
      </Item>
      <Item flex={1.5}>
        <Checkbox isCheck={isCheck} onClick={onClick} isDisable={isDisable} />
      </Item>
    </>
  );
}

const Item = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.flex};
  height: 52px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  @media ${(props) => props.theme.laptop} {
    display: none;
  }
`;

const Text = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const ResetButton = styled.button<{ isReset?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 5px 16px;
  border-radius: 4px;
  border: 2px solid
    ${(props) =>
      props.isReset
        ? props.theme.priamryColors.primary
        : props.theme.grayColors.scale3};
  color: ${(props) =>
    props.isReset
      ? props.theme.priamryColors.primary
      : props.theme.grayColors.scale3};
`;

const Input = styled.input`
  width: 20px;
  height: 20px;
  appearance: none;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.grayColors.scale4};
  :checked {
    background-color: ${(props) => props.theme.priamryColors.primary};
  }
`;

export default SupplyInputBtn;
