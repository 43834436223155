import React from 'react';
import styled from 'styled-components';

import { useHomeCalendar } from '@/hooks/useHomeCalendar';
import LoadingView from '../common/LoadingView';
import HomeHeader from './HomeHeader';
import RecentRefund from './RecentRefund';
import HomeNotice from './HomeNotice';

function HomeContent() {
  const { data } = useHomeCalendar();

  return (
    <Container>
      {data ? (
        <>
          <HomeHeader medicalInfo={data} />
          <RecentRefund medicalInfo={data} />
          <HomeNotice medicalNotice={data.notice} />
        </>
      ) : (
        <LoadingView />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media ${(props) => props.theme.laptop} {
    gap: 24px;
  }
`;

export default HomeContent;
