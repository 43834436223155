import { useAppDispatch, useAppSelecotr } from '@/hooks/useReduxHooks';
import { setSizeSeletor } from '@/store/modules/date';
import React from 'react';
import styled from 'styled-components';
import FlexWrap from './FlexWrap';

function ViewSelector() {
  const { size } = useAppSelecotr((state) => state.date);
  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(setSizeSeletor(e.target.value));
  };
  return (
    <ViewContainer>
      <Select value={size} onChange={onChange}>
        <Option value='10'>10개씩 보기</Option>
        <Option value='100'>100개씩 보기</Option>
        <Option value='500'>500개씩 보기</Option>
      </Select>
    </ViewContainer>
  );
}

const ViewContainer = styled(FlexWrap)`
  position: absolute;
  right: 0;
  margin: 25px 0;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const Select = styled.select`
  padding: 4px 8px;
`;
const Option = styled.option``;

export default ViewSelector;
