import React, { useState } from 'react';
import styled from 'styled-components';

import logoImage from '@/assets/common/logoColor.png';
import { useForm } from 'react-hook-form';
import ErrorMessage from '@/components/common/ErrorMessage';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { login } from '@/api/user';
import { ISigInPayload, ISignInResponse } from '@/types/user';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { loginUserState, productTypesState } from '@/store/modules/user';
import LoginModal from '@/components/login/LoginModal';
import Footer from '@/components/common/Footer';

function Login() {
  const [isModal, setIsModal] = useState(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      businessNumber: '',
      password: '',
    },
  });

  const loginMutation = useMutation<
    ISignInResponse,
    AxiosError<any>,
    ISigInPayload
  >((payload) => login(payload), {
    retry: false,
    onSuccess: (res) => {
      localStorage.setItem('loginToken', res.accessToken);
      localStorage.setItem('name', res.hospitalName);
      localStorage.setItem('profileImg', res.hospitalImagePath || '');
      dispatch(loginUserState(true));
      dispatch(productTypesState(res.productTypes));
    },
    onError: (error) => {
      console.log('에러', error);
      const code = error.response?.data.code;
      const messgae = error.response?.data.message;
      if (code === 'H0001') {
        setError('businessNumber', {
          message: '등록되지 않은 사업자번호 입니다.',
        });
      }

      if (messgae === 'Invalid Password') {
        setError('password', {
          message: '비밀번호가 틀립니다.',
        });
      }
    },
  });

  const onSubmit = async ({ businessNumber, password }: ISigInPayload) => {
    const payload = {
      businessNumber,
      password,
    };
    loginMutation.mutate(payload);
  };

  return (
    <Wrapper>
      <LoginContainer>
        <Container>
          <Header>
            <Logo src={logoImage} />
            <Title>의료용역 환급 서비스</Title>
          </Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register('businessNumber', {
                required: '아이디를 입력하세요.',
              })}
              maxLength={10}
              type='text'
              placeholder='사업자 등록번호 또는 법인사업자번호 입력'
            />
            {errors.businessNumber && (
              <ErrorMessage text={errors.businessNumber.message} />
            )}

            <Input
              {...register('password', { required: '비밀번호를 입력하세요.' })}
              type='password'
              placeholder='비밀번호 입력'
            />
            {errors.password && <ErrorMessage text={errors.password.message} />}
            <Button>로그인</Button>
          </Form>
          <IdPasswrodFind onClick={() => setIsModal(true)}>
            아이디/비밀번호 찾기
          </IdPasswrodFind>
        </Container>
        {isModal && <LoginModal setIsModal={setIsModal} />}
      </LoginContainer>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-height: calc(100vh);
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 72px 60px 42px 60px;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.blueColors.scale1};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px;
  height: 55px;
`;

const Title = styled.h2`
  margin-top: 8px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Input = styled.input`
  padding: 13px 11px;
  border-radius: 4px;
  width: 300px;
  height: 48px;
  color: ${(props) => props.theme.grayColors.scale1};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  :focus {
    border: 2px solid ${(props) => props.theme.priamryColors.primary};
  }
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.pointColors.white};
  background-color: #246cf6;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
`;

const IdPasswrodFind = styled.p`
  margin-top: 40px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.grayColors.scale2};
`;

export default Login;
