import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import RefundContent from '@/components/refund/RefundContent';
import RefundComplete from '@/components/refund/PaymentComplete/RefundComplete';
import Footer from '@/components/common/Footer';

function Refund() {
  const [isRefund, setIsRefund] = useState(false);

  return (
    <Container>
      {isRefund ? (
        <RefundComplete setIsRefund={setIsRefund} />
      ) : (
        <RefundContent setIsRefund={setIsRefund} />
      )}
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1500px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  background-color: #fcfdff;
`;

export default Refund;
