import React, { Dispatch, SetStateAction, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { medicalInfo } from '@/api/medical';
import { IMedicalResponse } from '@/types/medical';
import FlexWrap from '../common/FlexWrap';
import LoadingView from '../common/LoadingView';
import Text from '../common/Text';
import MedicalAccount from './MedicalAccount';
import MedicalInfo from './MedicalInfo';
import PasswordChange from './PasswordChange';

function MedicalContent() {
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  const { data: medicalData, isLoading } = useQuery<
    IMedicalResponse,
    AxiosError
  >('medical', medicalInfo, {
    retry: false,
    onSuccess: (res) => {},
    onError: (error) => {
      console.log('error', error);
    },
  });

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Container>
      <Title>유치기관 정보</Title>
      {isPasswordChange ? (
        <PasswordChange setIsPasswordChange={setIsPasswordChange} />
      ) : (
        <>
          <SubTitle>유치기관 정보 확인/수정</SubTitle>
          <Text text='유치기관 정보 수정이 필요하신 경우, 상담센터 (02-6275-8011)로 문의주시길 바랍니다.' />
          {medicalData && (
            <>
              <MedicalInfo
                medicalData={medicalData}
                setIsPasswordChange={setIsPasswordChange}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 28px;
  padding: 22px 40px 85px 40px;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  margin-top: 10px;
`;

export default MedicalContent;
