import { notice } from '@/api/notice';
import { INoticeResponse } from '@/types/notice';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useNotice = (page: number = 0, size: number = 5) => {
  const [totalPage, setTotalPage] = useState(0);
  const params = {
    page,
  };

  const { data, refetch } = useQuery<INoticeResponse, AxiosError>(
    'notice',
    () => notice(params),
    {
      retry: false,
      onSuccess: (res) => {
        setTotalPage(res.totalPages);
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  return { data, totalPage, refetch };
};
