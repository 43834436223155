import {
  IRefundPayload,
  IRefundResponse,
  IExcelBody,
  IExcelResponse,
  IParams,
  IRefundEditResponse,
  IRefundEditRequestResponse,
  IRefundCanclePayload,
  IRefundDetailResponse,
  IhospitalFindResponse,
  excelDownloadPaylod,
} from '@/types/refund';

import api from '..';
import { getFormattedTableData } from '@/util/format';
import { AccoutUpdatePayload } from '@/components/RefundDetail/DetailModal';
// 환급 신청
export const refundRegister = async (payload: IRefundPayload) => {
  const res = await api.postAxios<IRefundResponse, IRefundPayload, null>(
    '/user/refund',
    payload
  );
  return res.data;
};

//엑셀 데이터 조회
export const fetchExcelData = async (payload: IExcelBody) => {
  const res = await api.postAxios<IExcelResponse, IExcelBody, null>(
    '/user/excel',
    payload
  );
  return getFormattedTableData(res.data);
};

// 환급내역
export const getRefundDetailData = async (params: IParams) => {
  const res = await api.getAxios<IRefundDetailResponse, IParams>(
    `/user/refund/history`,
    params
  );
  return res.data;
};
export const getSerachRefundData = async (params: IParams) => {
  const res = await api.getAxios<IRefundDetailResponse, IParams>(
    `/user/refund`,
    params
  );
  return res.data;
};

// 업로드
export const upload = async (payload: IRefundPayload) => {
  const res = await api.postAxios<null, any, null>(
    `/user/refund/upload`,
    payload
  );
  return res.data;
};

// 환급취소
export const refundCancle = async (payload: IRefundCanclePayload) => {
  const res = await api.patchAxios<null, IRefundCanclePayload, null>(
    `/user/refund/cancel`,
    payload
  );
  return res.data;
};

// 환급 수정 데이터 불러오기
export const refundEditRequest = async (payload: number) => {
  const res = await api.getAxios<IRefundEditRequestResponse, number>(
    `/user/refund/update/${payload}`
  );
  return res.data;
};

// 환급 수정
export const refundEdit = async (
  refundIndex: string,
  payload: IRefundPayload
) => {
  const res = await api.patchAxios<IRefundEditResponse, IRefundPayload, null>(
    `/user/refund/update/${refundIndex}`,
    payload
  );
  return res.data;
};

// 다운로드
export const excelDownload = async ({ startDate, endDate }: any) => {
  const res = await api.getAxios<any, excelDownloadPaylod>(
    `/user/refund/download`,
    { startDate, endDate },
    { responseType: 'blob' }
  );
  return res.data;
};

// 병원명 찾기
export const hospitalFind = async () => {
  const res = await api.getAxios<IhospitalFindResponse[], null>(
    `/user/hospital/managed`
  );
  return res.data;
};

// 외국인 환급건 개별 조회
export const touristRefundInfoGet = async (touristIndex: number) => {
  const res = await api.getAxios<AccoutUpdatePayload, null>(
    `/user/tourist/account/${touristIndex}`
  );
  return res.data;
};

// 외국인 환급건 업데이트
export const touristRefundInfoUpdate = async (
  touristIndex: number,
  payload: AccoutUpdatePayload
) => {
  const res = await api.postAxios<null, AccoutUpdatePayload, null>(
    `/user/tourist/account/${touristIndex}`,
    payload
  );
  return res.data;
};
// 송금 완료
export const refundRemittance = async (refundIndex: number) => {
  const res = await api.patchAxios<boolean, number, null>(
    `user/refund/transfer/${refundIndex}`
  );
  return res.data;
};
