import React, { useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { excelDownload } from '@/api/refund';
import { useAppSelecotr } from '@/hooks/useReduxHooks';
import { setSearchTypeSeletor, setValueSeletor } from '@/store/modules/date';
import { excelDownloadPaylod } from '@/types/refund';
import DateSelectorPeriod from '../common/DateSelectorPeriod';
import FlexWrap from '../common/FlexWrap';
import Typography from '../common/Typography';

const filterList = [
  { name: '병원명', searchType: 'HOSPITAL_NAME' },
  { name: '이름', searchType: 'TOURIST_NAME' },
  { name: '여권번호', searchType: 'PASSPORT_NUMBER' },
];

function Header() {
  const [searchType, setSearchType] = useState('HOSPITAL_NAME');
  const [keyword, setKeyword] = useState('');
  const { startDate, endDate } = useAppSelecotr(
    (state) => state.date.refundDetail
  );

  const dispatch = useDispatch();

  const { refetch } = useQuery<any, excelDownloadPaylod>(
    ['refundDetail', startDate, endDate],
    async () =>
      await excelDownload({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      }),
    {
      enabled: false,
      retry: false,
      onSuccess: (s3Url) => {
        const blob = new Blob([s3Url]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `test.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      },
      onError: () => {
        alert(
          '다운로드에 실패하였습니다. 아래 연락처로 문의바랍니다.\nT. 02-6275-8011 (평일 오전 8시 - 오후 5시)'
        );
      },
    }
  );

  const onSubmitSearch = (e: any) => {
    e.preventDefault();
    dispatch(setValueSeletor(keyword));
    dispatch(setSearchTypeSeletor(searchType));
  };

  const onClickFileDownload = () => {
    refetch();
  };

  return (
    <Container>
      {/* 기간선택 */}
      <FlexWrap justifyContent='space-between'>
        <Typography size='20px' lineHeight='28px' fontWeight='500'>
          환급내역 조회
        </Typography>
        <FlexWrap gap='20px'>
          <DateSelectorPeriod
            currStartDate={startDate}
            currEndDate={endDate}
            isRefund
          />
          <FileDownloadBtn onClick={onClickFileDownload}>
            파일 다운로드
          </FileDownloadBtn>
        </FlexWrap>
      </FlexWrap>
      {/* 필터검색 */}
      <FilterContainer alignItems='center'>
        <FilterList>
          {filterList.map((item, idx) => (
            <FlexWrap key={idx} gap='8px'>
              <StyledRadio
                type='radio'
                value='MEDIVISOR'
                onChange={() => setSearchType(item.searchType)}
                checked={searchType === item.searchType}
              />
              <Typography size='16px' lineHeight='24px' fontWeight='400'>
                {item.name}
              </Typography>
            </FlexWrap>
          ))}
        </FilterList>
        <InputContainer onSubmit={onSubmitSearch}>
          <SearchInput
            placeholder='찾으실 환급내역을 입력해주세요.'
            onChange={(e) => setKeyword(e.target.value)}
          />
        </InputContainer>
      </FilterContainer>
    </Container>
  );
}
const Container = styled.div``;
const FileDownloadBtn = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #0b42ad;
  padding: 4px 20px;
  border: 2px solid #0b42ad;
  border-radius: 4px;
  background-color: #fff;
`;
const FilterContainer = styled(FlexWrap)`
  margin-top: 36px;
`;
const FilterList = styled(FlexWrap)`
  gap: 16px;
`;
const StyledRadio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #cbccce;
  cursor: pointer;
  &::disabled {
    cursor: default;
  }
  &:checked {
    border: 4px solid #354eff;
    background-color: #ffffff;
  }
`;
const InputContainer = styled.form`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  flex: 1;
`;
const SearchInput = styled.input`
  width: 100%;
  font-size: 16px;
  margin-left: 24px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 20px;
  min-width: 480px;
  border: 1px solid #cbccce;
  border-radius: 4px;
  ::placeholder {
    color: #cbccce;
  }
`;
export default Header;
