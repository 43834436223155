import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { RefundHomeFullPaymentHeader } from '@/constants/refundGraph';
import { IHomeCalendarResponae } from '@/types/home';
import Button from '../common/Button';
import FlexWrap from '../common/FlexWrap';
import RecentRefundItem from './RecentRefundItem';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

function RecentRefund({ medicalInfo }: IProps) {
  const navigate = useNavigate();
  const { latestRefundInfo } = medicalInfo;

  return (
    <Container dir='column'>
      <TitleContainer justifyContent='space-between' alignItems='center'>
        <Title>환급내역</Title>
        <Button
          text='+ 더보기'
          color='#757575'
          fontWeight
          onClick={() => navigate('/refund-detail')}
        />
      </TitleContainer>
      <Header>
        {RefundHomeFullPaymentHeader.map((item) => (
          <HeaderTitle key={item.id} flex={item.flex}>
            {item.title}
          </HeaderTitle>
        ))}
      </Header>
      <RefundList>
        {latestRefundInfo.map((item, idx) => (
          <RecentRefundItem key={idx} info={item} idx={idx} />
        ))}
      </RefundList>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  position: relative;
  margin-top: 28px;
  padding: 22px 40px 85px 40px;
  @media ${(props) => props.theme.laptop} {
    margin-top: 0px;
  }
`;

const TitleContainer = styled(FlexWrap)`
  width: 100%;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  background-color: #eaf0fa;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const HeaderTitle = styled.p<{ flex?: number }>`
  padding: 12px 0;
  flex: ${(props) => props.flex};
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
    padding: 9px 0;
  }
`;

const RefundList = styled.ul`
  width: 100%;
  background-color: ${(props) => props.theme.pointColors.white};
`;

export default RecentRefund;
