import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

interface IProps {
  menuItem: IMenuItem;
}

interface IMenuItem {
  id: number;
  title: string;
  link: string;
  uri: { active: string; notActive: string };
}

function SideMenuItem({ menuItem }: IProps) {
  const [isCurrent, setIsCurrent] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (menuItem.link === pathname) {
      setIsCurrent(true);
    } else {
      setIsCurrent(false);
    }
  }, [pathname]);

  return (
    <Container isCurrent={isCurrent}>
      <Icon
        src={isCurrent ? menuItem.uri.active : menuItem.uri.notActive}
        width='24px'
        height='24px'
      />
      <Title isCurrent={isCurrent}>{menuItem.title}</Title>
    </Container>
  );
}

const Container = styled.li<{ isCurrent: boolean }>`
  display: flex;
  gap: 14px;
  padding: 10px 0 10px 40px;
  background-color: ${(props) => (props.isCurrent ? '#D3E2FD' : 'transparent')};
`;

const Icon = styled.img`
  object-fit: contain;
`;

const Title = styled.h3<{ isCurrent: boolean }>`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  color: ${(props) =>
    props.isCurrent ? '#0B42AD' : props.theme.blueColors.scale4};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

export default SideMenuItem;
