import { refundDetailColor, refundDetailText } from '@/util/common';
import { comma } from '@/util/format';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../common/Button';
import DetailModal from '../RefundDetail/DetailModal';

function RecentRefundItem({ info, idx }: any) {
  const [isModal, setIsModal] = useState(false);

  return (
    <>
      <RefundItem key={info.refundIndex}>
        <Content flex={1}>{'0' + Number(idx + 1)}</Content>
        <Content flex={2.5}>{info.supplyDate}</Content>
        <Content flex={2.5}>{info.hospitalName}</Content>
        <Content flex={1}>{info.nation}</Content>
        <Content flex={2.5}>
          {info.touristFirstName + ' ' + info.touristName}
        </Content>
        <Content flex={2.5}>{info.touristPassportNumber}</Content>
        <Content flex={2.5}>{comma(info.totalPrice)} 원</Content>
        <Content flex={2.5}>{comma(info.refund)} 원</Content>
        <Content flex={2.5}>{info.refundCorp}</Content>
        <Content flex={2.5}>
          <Button
            text={refundDetailText(info.refundStatus, info.isTransfer)}
            width='110px'
            height='32px'
            color={refundDetailColor(info.refundStatus, info.isTransfer)}
            border={`2px solid ${refundDetailColor(
              info.refundStatus,
              info.isTransfer
            )}`}
            borderRadius='4px'
            fontWeight
            backgroundColor='#FFF'
            onClick={() => setIsModal(true)}
          />
        </Content>
      </RefundItem>
      {isModal && <DetailModal refund={info} setIsModal={setIsModal} />}
    </>
  );
}

const RefundItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const Content = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  flex: ${(props) => props.flex};
  padding: 12px 0;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  @media ${(props) => props.theme.laptop} {
    padding: 9px 0;
    text-align: center;
  }
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

export default RecentRefundItem;
