import { combineReducers } from 'redux';
import refund from './refund';
import validate from './validate';
import user from './user';
import notice from './notice';
import date from './date';
import product from './product';

const rootReducer = combineReducers({
  refund,
  validate,
  user,
  notice,
  date,
  product,
});
export default rootReducer;
