import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import refundNotImage from '@/assets/refund/refundNot.png';
import { RefundGraphHeader } from '@/constants/refundGraph';
import { useRefndDetail } from '@/hooks/useRefundDetail';
import { IRefundDetailContent } from '@/types/refund';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import Paging from '../common/Paging';
import ViewSelector from '../common/ViewSelector';
import TableHeader from '../refund/PaymentComplete/TableHeader';
import RefundDetailItem from './RefundDetailItem';

function RefundDetailList() {
  const [pageNumber, setPageNumber] = useState(0);
  const [removeArray, setRemoveArray] = useState<number[]>([]);
  const { data, totalPage } = useRefndDetail(pageNumber);

  const actvieRefund = !!data && !!data.totalPages;
  const methods = useForm({
    defaultValues: {
      checked: false,
    },
  });

  return (
    <FormProvider {...methods}>
      <TableHeader titleList={RefundGraphHeader} />
      {actvieRefund ? (
        <List>
          {data.content.map((refund: IRefundDetailContent, index: number) => (
            <RefundDetailItem
              key={refund.refundIndex}
              refund={refund}
              index={index}
              size={data.size}
              currentPage={data.number}
              removeArray={removeArray}
              pageNumber={pageNumber}
              totalElements={data.totalElements}
              setRemoveArray={setRemoveArray}
            />
          ))}
        </List>
      ) : (
        <RefundNotContainer>
          <Image src={refundNotImage} />
          <Text>아직 환급된 내역이 없습니다.</Text>
        </RefundNotContainer>
      )}
      <BottomContent alignItems='center' justifyContent='center'>
        <Paging setPageNumber={setPageNumber} totalPage={totalPage} />
        {data && <ViewSelector />}
      </BottomContent>
    </FormProvider>
  );
}

const List = styled.ul`
  margin-bottom: 30px;
`;

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.grayColors.scale2};
`;
const BottomContent = styled(FlexWrap)`
  position: relative;
`;
export default RefundDetailList;
