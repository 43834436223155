import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import uploadSuccessImage from '@/assets/refund/uploadSuccess.png';
import Button from '@/components/common/Button';
import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import ExcelTable from '@/components/refund/ExcelTable';
import { useAppSelecotr } from '@/hooks/useReduxHooks';
import { comma } from '@/util/format';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { IUploadExcelImagePayload } from '@/types/user';
import { uploadExel } from '@/api/user';
import { convertComponentToImage } from '@/util/common';

type RefundCompleteProps = {
  setIsRefund: Dispatch<SetStateAction<boolean>>;
};

function RefundComplete({ setIsRefund }: RefundCompleteProps) {
  const { touristIndex, refundIndex } = useAppSelecotr((state) => state.user);
  const { totalPrice, totalRefund } = useAppSelecotr((state) => state.refund);
  console.log('priceList', totalPrice);

  const navigate = useNavigate();
  const excelRef = useRef<MutableRefObject<HTMLDivElement | null>>(null);

  const uploadExcelImage = async () => {
    if (excelRef) {
      const blob = await convertComponentToImage(excelRef.current as any);
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('refundId', refundIndex);
      uploadMutation.mutate({ formData });
    }
  };
  const uploadMutation = useMutation<
    number,
    AxiosError,
    IUploadExcelImagePayload
  >((payload) => uploadExel(payload), {
    retry: false,
  });

  useEffect(() => {
    const timer = setTimeout(uploadExcelImage, 500);
    return () => clearTimeout(timer);
  }, []);

  const headerData = ['의료금액', '환급액', '결제금액'];

  return (
    <Container>
      <Icon src={uploadSuccessImage} />
      <Title>환급 신청 완료</Title>
      <Typography>환급 신청이 완료되었습니다.</Typography>
      <GraphContainer dir='column'>
        <Header>
          {headerData.map((item) => (
            <HeaderItem>{item}</HeaderItem>
          ))}
        </Header>
        <Content>
          <ContentItem>{comma(totalPrice)}원</ContentItem>
          <ContentItem>{comma(totalRefund)}원</ContentItem>
          <ContentItem>{comma(totalRefund)}원</ContentItem>
        </Content>
      </GraphContainer>
      <FlexWrap gap='30px'>
        <Button
          text='환급하기'
          width='120px'
          height='42px'
          border='1px solid #0B42AD'
          color='#0B42AD'
          backgroundColor='#FFF'
          borderRadius='2px'
          onClick={() => setIsRefund(false)}
        />
        <Button
          text='환급내역 보기'
          width='120px'
          height='42px'
          color='#FFF'
          backgroundColor='#0B42AD'
          borderRadius='2px'
          onClick={() => navigate('/refund-detail')}
        />
      </FlexWrap>
      <Hidden>
        <ExcelContainer ref={excelRef}>
          <ExcelTable refundIndex={+refundIndex} touristIndex={+touristIndex} />
        </ExcelContainer>
      </Hidden>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 40px 40px 85px 40px;
  min-height: calc(100vh - 80px);
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
`;

const Title = styled.h1`
  white-space: pre-wrap;
  text-align: center;
  position: relative;
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
`;

const Hidden = styled.div`
  opacity: 0;
`;
const ExcelContainer = styled.div<{ ref: any }>`
  width: 1200px;
  position: absolute;
  top: -2500px;
  left: 400px;
`;
const GraphContainer = styled(FlexWrap)`
  border: 1px solid #cbccce;
`;
const Header = styled(FlexWrap)`
  width: 100%;
  background-color: #eaf0fa;
`;
const HeaderItem = styled.p`
  width: 360px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 15px 120px;
  text-align: center;
  border-bottom: 1px solid #cbccce;
  :not(:last-child) {
    border-right: 1px solid #cbccce;
  }
`;
const Content = styled(FlexWrap)`
  justify-content: center;
  width: 100%;
`;
const ContentItem = styled.p`
  width: 360px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 15px 120px;
  text-align: center;
  :not(:last-child) {
    border-right: 1px solid #cbccce;
  }
`;
export default RefundComplete;
