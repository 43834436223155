export const ChnMethodList = [
  '알리페이',
  '유니온 페이',
  '위챗페이',
  '계좌이체',
];
export const ChnBanckList = [
  'Agricultural Bank of China (中国农业银行)',
  'Bank of Beijing (北京银行)',
  'Bank of Changsha (长沙银行)',
  'Bank of Chengdu (成都银行)',
  'Bank of China (中国银行)',
  'Bank of Chongqing (重庆银行)',
  'Bank of Communications (交通银行)',
  'Bank of Dalian (大连银行)',
  'Bank of Dongguan (东莞银行)',
  'Bank of Fuxin (阜新银行)',
  'Bank of Ganzhou (赣州银行)',
  'Bank of Guangzhou (广州银行)',
  'Bank of Guiyang (贵阳银行)',
  'Bank of Hangzhou (杭州银行)',
  'Bank of Hebei (河北银行)',
  'Bank of Inner Mongolia (内蒙古银行)',
  'Bank of Jiangsu (江苏银行)',
  'Bank of Jinzhou (锦州银行)',
  'Bank of Kunlun (昆仑银行)',
  'Bank of Liuzhou (柳州银行)',
  'Bank of Nanchang (南昌银行)',
  'Bank of Nanjing (南京银行)',
  'Bank of Ningbo (宁波银行)',
  'Bank of Ningxia (宁夏银行)',
  'Bank of Qingdao (青岛银行)',
  'Bank of Qinghai (青海银行)',
  'Bank of Shanghai (上海银行)',
  'Bank of Suzhou (苏州银行)',
  'Bank of Taizhou (台州银行)',
  'Bank of Tianjin (天津银行)',
  'Bank of Urumqi Co Ltd (乌鲁木齐银行)',
  'Bank of Wenzhou (温州银行)',
  'Bank of Xi an (西安银行)',
  'Bank of Yingkou (营口银行)',
  'Bank of Zhengzhou (郑州银行)',
  'Baoshang Bank (包商银行)',
  'Beijing Rural Commercial Bank (北京农村商业银行)',
  'Bohai Bank (渤海银行)',
  'China Citic Bank (中信银行)',
  'China Construction Bank (中国建设银行)',
  'China Everbright Bank (中国光大银行)',
  'China Guangfa Bank (中国广发银行)',
  'China Merchants Bank (中国招商银行)',
  'China Minsheng Banking Co. Ltd (中国民生银行)',
  'China Resources Bank of Zhuhai Co., Ltd (珠海华润银行)',
  'China Zheshang Bank (浙商银行)',
  'Chongqing Rural Commercial Bank (渝农商行)',
  'Dezhou Bank (德州银行)',
  'Fudian Bank (富滇银行)',
  'Fujian Haixia Bank (福建海峡银行)',
  'Guangdong Nanyue Bank (广东南粤银行)',
  'Guangxi Beibu Gulf Bank (广西北部湾银行)',
  'Guangzhou Rural Commercial Bank (广州农业银行)',
  'Guilin Bank (桂林银行)',
  'Hana Bank (韩亚银行)',
  'Hankou Bank (汉口银行)',
  'Hua Xia Bank (华夏银行)',
  'Huishang Bank (徽商银行)',
  'Industrial & Commercial Bank of China (中国工商银行)',
  'Industrial Bank Co. Ltd (兴业银行)',
  'Industrial Bank of Korea (韩国中小企业银行)',
  'Jilin Bank (吉林银行)',
  'Jinshang Bank (晋商银行)',
  'Laishang Bank (莱商银行)',
  'Linshang Bank (临商银行)',
  'Longjiang Bank (龙江银行)',
  'Ping An Bank (平安银行)',
  'Postal Savings Bank of China (中国邮政储蓄银行)',
  'Qilu Bank (齐鲁银行)',
  'Qishang Bank (齐商银行)',
  'Shanghai Rural Commercial Bank (上海农村商业银行)',
  'Shenzhen Rural Commercial Bank (深圳农村商业银行)',
  'Tianjin Rural Cooperative Bank (天津农村合作社银行)',
  'Woori Bank (友利银行)',
  'Xiamen Bank (厦门银行)',
  'Yantai Bank (烟台银行)',
  'Zhejiang Chouzhou Commercial Bank (浙江稠州商业银行)',
  'Zhejiang Tailong Commercial Bank (浙江泰隆商业银行)',
];

export const JpnBankList = [
  '77 Bank ｼﾁｼﾞﾕｳｼﾁ',
  'Abashiri Shinkin bank ｱﾊﾞｼﾘｼﾝｷﾝ',
  'Abukuma Shinkin bank ｱﾌﾞｸﾏｼﾝｷﾝ',
  'Aeon Bankｲｵﾝ',
  'Aichi Bankｱｲﾁ',
  'Aichi Doctors Credit Cooperativeｱｲﾁｹﾝｲｼｼﾝｸﾐ',
  'Aichi Shinkin bankｱｲﾁｼﾝｷﾝ',
  'Aichi Shoginｱｲﾁｼﾖｳｷﾞﾝｼﾝｸﾐ',
  'Akita Bankｱｷﾀ',
  'Amagasaki Shinkin Bankｱﾏｶﾞｻｷｼﾝｷﾝ',
  'Aomori Bankｱｵﾓﾘ',
  'Aozora Bankｱｵｿﾞﾗ',
  'Ashikaga Bankｱｼｶｶﾞ',
  'Awa Bankｱﾜ',
  'Bank of America Corporation, BofA ｱﾒﾘｶ',
  'Chiba Bankﾁﾊﾞ',
  'Chiba Kogyo Bankﾁﾊﾞｺｳｷﾞﾖｳ',
  'Chikubo Bankﾁｸﾎｳ',
  'China Bankﾁﾕｳｺﾞｸ',
  'Chukyo Bankﾁﾕｳｷﾖｳ',
  'Citibank, N.Aｼﾃｲﾊﾞﾝｸ',
  'Daisan Bankﾀﾞｲｻﾝ',
  'Daishi Bankﾀﾞｲｼ',
  'Daito Bankﾀﾞｲﾄｳ',
  'Daiwa Next Bankﾀﾞｲﾜﾈｸｽﾄ',
  'Deutsche Bankﾄﾞｲﾂ',
  'Ehime Bankｴﾋﾒ',
  'First Bank of Toyamaﾄﾔﾏﾀﾞｲｲﾁ',
  'Fukuho Bankﾌｸﾎｳ',
  'Fukui Bankﾌｸｲ',
  'Fukuoka Bankﾌｸｵｶ',
  'Fukuoka Chuo Bankﾌｸｵｶﾁﾕｳｵｳ',
  'Fukushima Bankﾌｸｼﾏ',
  'GMO Aozora Net Bank, Ltd.ｼﾞ-ｴﾑｵ-ｱｵｿﾞﾗﾈﾂﾄ',
  'Gunma Bankｸﾞﾝﾏ',
  'Hachijuni banksﾊﾁｼﾞﾕｳﾆ',
  'Higashi-Nippon Bankﾋｶﾞｼﾆﾂﾎﾟﾝ',
  'Higo Bankﾋｺﾞ',
  'Hiroshima Bankﾋﾛｼﾏ',
  'Hokkaido Bankﾎﾂｶｲﾄﾞｳ',
  'Hokkoku Bankﾎﾂｺｸ',
  'Hokuetsu Bankﾎｸｴﾂ',
  'Hokuriku Bankﾎｸﾘｸ',
  'Hokuto Bankﾎｸﾄ',
  'Howa Bankﾎｳﾜ',
  'Hyaku Jyushi Bankﾋﾔｸｼﾞﾕｳｼ',
  'Hyakugo banksﾋﾔｸｺﾞ',
  'Ikeda Senshu Bankｲｹﾀﾞｾﾝｼﾕｳ',
  'Iwate Bankｲﾜﾃ',
  'Iyo Bankｲﾖ',
  'Japan Net Bankｼﾞﾔﾊﾟﾝﾈﾂﾄ',
  'Japan Post Bankﾕｳﾁﾖ',
  'Jibun Bankｼﾞﾌﾞﾝ',
  'Jouyou Bankｼﾞﾖｳﾖｳ',
  'JPMorgan Chase Bank, N.Aｼﾞｴ-ﾋﾟ-ﾓﾙｶﾞﾝ',
  'Juroku banksｼﾞﾕｳﾛｸ',
  'Jyuhachi Bankｼﾞﾕｳﾊﾁ',
  'Kagawa Bankｶｶﾞﾜ',
  'Kagoshima Bankｶｺﾞｼﾏ',
  'Kanagawa Bankｶﾅｶﾞﾜ',
  'Kansai Mirai Bankｶﾝｻｲﾐﾗｲ',
  'Keiyo Bankｹｲﾖｳ',
  'Kiraboshi Bankｷﾗﾎﾞｼ',
  'Kirayaka Bankｷﾗﾔｶ',
  'Kitakyushu Bankｷﾀｷﾕｳｼﾕｳ',
  'Kita-Nippon Bankｷﾀﾆﾂﾎﾟﾝ',
  'Kiyou Bankｷﾖｳ',
  'Kochi Bankｺｳﾁ',
  'Kumamoto Bankｸﾏﾓﾄ',
  'Kyoto Bankｷﾖｳﾄ',
  'Kyoto Chuo Shinkin Bankｷﾖｳﾄﾁﾕｳｵｳｼﾝｷﾝ',
  'Lawson Bankﾛ-ｿﾝ',
  'Michinoku Bankﾐﾁﾉｸ',
  'Mie Bankﾐｴ',
  'Minami Nippon Bankﾐﾅﾐﾆﾂﾎﾟﾝ',
  'Minato Bankﾐﾅﾄ',
  'Mitsubishi UFJ Bankﾐﾂﾋﾞｼﾕ-ｴﾌｼﾞｴｲ',
  'Mitsubishi UFJ Trust and Banking CorporationﾐﾂﾋﾞｼUFJｼﾝﾀｸ',
  'Miyazaki Bankﾐﾔｻﾞｷ',
  'Miyazaki Taiyo Bankﾐﾔｻﾞｷﾀｲﾖｳ',
  'Mizuho Bankﾐｽﾞﾎ',
  'Mizuho Trust and Banking Co.,Ltd.ﾐｽﾞﾎｼﾝﾀｸ',
  'Momiji Bankﾓﾐｼﾞ',
  'Musashino Bankﾑｻｼﾉ',
  'Nagano Bankﾅｶﾞﾉ',
  'Nagasaki Bankﾅｶﾞｻｷ',
  'Nagoya Bankﾅｺﾞﾔ',
  'Nanto Bankﾅﾝﾄ',
  'Nishi Nihon City Bankﾆｼﾆﾂﾎﾟﾝｼﾃｲ',
  'North Pacific Bankﾎｸﾖｳ',
  'Oita Bankｵｵｲﾀ',
  'Okinawa Bankｵｷﾅﾜ',
  'Okinawa Kaiho Bankｵｷﾅﾜｶｲﾎｳ',
  'Oogaki Kyoritsu Bankｵｵｶﾞｷｷﾖｳﾘﾂ',
  'Orix Bank Corporationｵﾘﾂｸｽ',
  'Osaka Shinkin Bankｵｵｻｶｼﾝｷﾝ',
  'Rakuten Bankﾗｸﾃﾝ',
  'Resona Bankﾘｿﾅ',
  'Ryukyu Bankﾘﾕｳｷﾕｳ',
  'Saga Bankｻｶﾞ',
  'Saga Kyoei Bankｻｶﾞｷﾖｳｴｲ',
  'Saikyo Bankｻｲｷﾖｳ',
  'Saitama Resona Bankｻｲﾀﾏﾘｿﾅ',
  'Sanin Goudou Bankｻﾝｲﾝｺﾞｳﾄﾞｳ',
  'SBJ Bankｴｽﾋﾞ-ｼﾞｴ-',
  'Sendai Bankｾﾝﾀﾞｲ',
  'Seven Bankｾﾌﾞﾝ',
  'Shiga Bankｼｶﾞ',
  'Shikoku Bankｼｺｸ',
  'Shimane Bankｼﾏﾈ',
  'Shimizu Bankｼﾐｽﾞ',
  'Shinsei Bankｼﾝｾｲ',
  'Juhachi Shinwa Bank 十八親和銀行',
  'Shizuoka Bankｼｽﾞｵｶ',
  'Shizuoka Chuo Bankｼｽﾞｵｶﾁﾕｳｵｳ',
  'Shonai Bankｼﾖｳﾅｲ',
  'SMBC Trust Bank Ltd.ｴｽｴﾑﾋﾞ-ｼ-ｼﾝﾀｸ',
  'Sony Bankｿﾆ-',
  'Sumishin SBI Net Bankｽﾐｼﾝｴｽﾋﾞ-ｱｲﾈﾂﾄ',
  'Sumitomo Mitsui Banking Corporationﾐﾂｲｽﾐﾄﾓ',
  'Suruga Bankｽﾙｶﾞ',
  'Taiko Bankﾀｲｺｳ',
  'Taisho Bankﾀｲｼﾖｳ',
  'Tajima Bankﾀｼﾞﾏ',
  'Tama Shinkin bankﾀﾏｼﾝｷﾝ',
  'The Hiroshima Shinkin Bankﾋﾛｼﾏｼﾝｷﾝ',
  'The Hongkong and Shanghai Banking Corporation Limiﾎﾝｺﾝｼﾔﾝﾊｲ',
  'The Kyoto Shinkin Bankｷﾖｳﾄｼﾝｷﾝ',
  'The Nomura Trust and Banking Co.,Ltd.ﾉﾑﾗｼﾝﾀｸ',
  'The Oita Mirai Shinkin Bankｵｵｲﾀﾐﾗｲｼﾝｷﾝ',
  'The Sumitomo Trust and Banking Company,Ltd.ﾐﾂｲｽﾐﾄﾓｼﾝﾀｸ',
  'Tochigi Bankﾄﾁｷﾞ',
  'Tohoku Bankﾄｳﾎｸ',
  'Tokushima Bankﾄｸｼﾏ',
  'Tokyo Star Bankﾄｳｷﾖｳｽﾀ-',
  'Tomato Bankﾄﾏﾄ',
  'Tottori Bankﾄﾂﾄﾘ',
  'Touhou Bankﾄｳﾎｳ',
  'Towa Bankﾄｳﾜ',
  'Toyama Bankﾄﾔﾏ',
  'Tsukuba Bankﾂｸﾊﾞ',
  'Yamagata Bankﾔﾏｶﾞﾀ',
  'Yamaguchi Bankﾔﾏｸﾞﾁ',
  'Yamanashi Chuo Bankﾔﾏﾅｼﾁﾕｳｵｳ',
  'Yokohama Bankﾖｺﾊﾏ',
];

export const ThbBankList = [
  'SIAM COMMERCIAL BANK',
  'BANGKOK BANK',
  'KASIKORN BANK',
  'THE ROYAL BANK OF SCOTLAND N.V.,',
  'KRUNG THAI BANK',
  'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION',
  'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.',
  'TMBThanachart Bank',
  'Bank of Thailand',
  'Mega International Commercial Bank PCL',
  'THE SIAM CITY BANK',
  'CITIBANK',
  'SUMITOMO MITSUI BANKING CORPORATION',
  'STANDARD CHARTERED BANK (THAI)',
  'CIMB THAI BANK',
  'UNITED OVERSEAS BANK (THAI)',
  'BANK OF AYUDHYA PUBLIC COMPANY LTD.',
  'BANK OF AMERICA, NATIONAL ASSOCIATION',
  'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK',
  'THE GOVERNMENT SAVINGS BANK',
  'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.',
  'DEUTSCHE BANK AG.',
  'THE GOVERNMENT HOUSING BANK',
  'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES',
  'MIZUHO CORPORATE BANK, LTD.',
  'BNP PARIBAS',
  'BANK OF CHINA LIMITED',
  'ISLAMIC BANK OF THAILAND',
  'TISCO BANK',
  'KIATNAKIN BANK',
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI)',
  'THE THAI CREDIT RETAIL BANK',
  'LAND AND HOUSES PUBLIC COMPANY LIMITED',
  'ANZ BANK (THAI) PUBLIC COMPANY LIMITED',
  'INDIAN OVERSEA BANK',
  'RHB BANK BERHAD',
  'EXPORT-IMPORT BANK OF THAILAND',
  'OVER SEA-CHINESE BANKING CORPORATION LIMITED',
  'SMALL AND MEDIUM ENTERPRISE DEVELOPMENT BANK OF THAILAND',
];
export const AusBankList = ['Bank Transfer Anywhere'];
export const CanBankList = [
  'ADS Canadian Bank',
  'B2B Bank',
  'Bank of Montreal',
  'The Bank of Nova Scotia',
  'Bridgewater Bank',
  'Caisse populaire acadienne ltee',
  'Canadian Imperial Bank of Commerce',
  'Canadian Tire Bank',
  'Canadian Western Bank',
  'Concentra Bank',
  'Continental Bank of Canada',
  'CS Alterna Bank',
  'DirectCash Bank',
  'Equitable Bank',
  'Exchange Bank of Canada',
  'First Nations Bank of Canada',
  'Home Bank',
  'General Bank of Canada',
  'HomEquity Bank',
  'Citco Bank Canada',
  'Laurentian Bank of Canada',
  'Manulife Bank of Canada',
  'National Bank of Canada',
  'VersaBank',
  'Presidents Choice Bank',
  'M & T Bank',
  'Rogers Bank',
  'Royal Bank of Canada',
  'Street Capital Bank of Canada',
  'Tangerine Bank',
  'The Toronto-Dominion Bank',
  'Vancity Community Investment Bank',
  'Wealth One Bank of Canada',
  'Zag Bank',
  'Amex Bank of Canada',
  'Bank of China (Canada)',
  'BofA Canada Bank',
  'Cidel Bank Canada',
  'Citibank Canada',
  'CTBC Bank Corp. (Canada)',
  'Habib Canadian Bank',
  'HSBC Bank Canada',
  'ICICI Bank Canada',
  'Industrial and Commercial Bank of China (Canada)',
  'J.P. Morgan Bank Canada',
  'KEB Hana Bank Canada',
  'Mega International Commercial Bank (Canada)',
  'SBI Canada Bank',
  'Shinhan Bank Canada',
  'Societe Generale (Canada Branch)',
  'UBS Bank (Canada)',
  'Walmart Canada Bank',
  'Bank of Canada',
  'Atlantic Central',
  'Caisse Populaire Group Financier Lt\u00E9e',
  'Central 1 Credit Union Vancouver',
  'Central 1 Credit Union Burlington',
  'Credit Union Central of Manitoba Limited',
  'Credit Union Central Alberta Limited',
  'Credit Union Central of Saskatchewan',
  'F\u00E9d\u00E9ration des caisses Desjardins du Quebec',
  'La F\u00E9d\u00E9ration des caisses populaires de lOntario Inc.',
  'L Alliance des caisses populaires de l Ontario, Lt\u00E9e.',
  'Golden Horseshoe Credit Union Limited',
  'Latvian Credit Union Limited',
  'ATB Financial',
  'Edward Jones',
  'The Canada Trust Company',
  'CIBC Trust Corporation',
  'Effort Trust Company',
  'Home Trust Company',
  'HSBC Mortgage Corporation (Canada)',
  'Investors Group Trust Co. Ltd.',
  'Trust La Laurentienne du Canada Inc.',
  'Manulife Trust Company',
  'Montreal Trust Company of Canada',
  'National Trust Company',
  'Peace Hills Trust Company',
  'Peoples Trust Company',
  'The Royal Trust Company',
  'Royal Trust Corporation of Canada',
  'Scotia Mortgage Corporation',
  'Sun Life Financial Trust Inc.',
  'TD Mortgage Corporation',
  'TD Pacific Mortgage Corporation',
  'Community Trust Company',
];
export const IdnBankList = [
  'BANK MANDIRI, TBK.',
  'BANK RAKYAT INDONESIA, TBK.',
  'BANK NEGARA INDONESIA, TBK.',
  'BANK CENTRAL ASIA, TBK.',
  'BANK CHINA CONSTRUCTION BANK INDONESIA, TBK.',
  'BANK CHINATRUST INDONESIA',
  'BANK CIMB NIAGA, TBK',
  'BANK COMMONWEALTH',
  'BANK DANAMON INDONESIA, TBK.',
  'BANK DBS INDONESIA',
  'BANK DINAR INDONESIA',
  'BANK DKI',
  'BANK EKSPOR INDONESIA',
  'BANK FAMA INTERNATIONAL',
  'BANK GANESHA',
  'BANK HANA',
  'BANK HARDA INTERNASIONAL',
  'BANK ICBC INDONESIA',
  'BANK INA PERDANA',
  'BANK INDEX SELINDO',
  'BANK INDIA',
  'BANK J TRUST INDONESIA, TBK.',
  'BANK JASA JAKARTA',
  'BANK KESEJAHTERAAN EKONOMI',
  'BANK MANDIRI TASPEN POS',
  'BANK NUSANTARA PARAHYANGAN',
  'BANK OCBC NISP, TBK.',
  'BANK OF AMERICA MERRILL-LYNCH',
  'BANK OF CHINA LIMITED',
  'BANK OF TOKYO MITSUBISHI UFJ, LTD.',
  'BANK OKE INDONESIA',
  'BANK PANIN',
  'BANK PANIN DUBAI SYARIAH',
  'BANK PEMBANGUNAN DAERAH BANTEN',
  'BANK PERMATA, TBK.',
  'BANK QNB INDONESIA',
  'BANK RABOBANK INTERNATIONAL INDONESIA',
  'BANK MASPION INDONESIA',
  'BANK MAYAPADA INTERNATIONAL, TBK.',
  'BANK MAYBANK INDONESIA SYRIAH TBK.',
  'BANK MAYBANK INDONESIA TBK.',
  'BANK MAYORA',
  'BANK MEGA, TBK.',
  'BANK MESTIKA DHARMA',
  'BANK MITRA NIAGA',
  'BANK MIZUHO INDONESIA',
  'BANK MNC INTERNASIONAL, TBK.',
  'BANK MUAMALAT INDONESIA TBK',
  'BANK MULTI ARTA SENTOSA',
  'BANK NATIONALNOBU',
  'BANK RBS',
  'BANK RESONA PERDANIA',
  'BANK ROYAL INDONESIA',
  'BANK SAHABAT SAMPOERNA',
  'BANK SBI INDONESIA',
  'BANK SHINHAN INDONESIA',
  'BANK SINARMAS',
  'BANK SUMITOMO MITSUI INDONESIA',
  'BANK SYARIAH BUKOPIN',
  'BANK SYARIAH MEGA INDONESIA',
  'BANK TABUNGAN NEGARA, TBK.',
  'BANK TABUNGAN PENSIUNAN NASIONAL',
  'BANK UOB INDONESIA',
  'BANK VICTORIA INTERNASIONAL',
  'BANK VICTORIA SYARIAH',
  'BANK WOORI SAUDARA ,TBK.',
  'BANK YUDHA BHAKTI',
  'BPD ACEH',
  'BPD BALI',
  'BPD BENGKULU',
  'BPD DAERAH ISTIMEWA YOGYAKARTA',
  'BPD JAMBI',
  'BPD JAWA TENGAH',
  'BPD JAWA TIMUR',
  'BPD KALIMANTAN BARAT',
  'BPD KALIMANTAN SELATAN',
  'BPD KALIMANTAN TENGAH',
  'BPD KALIMANTAN TIMUR',
  'BPD LAMPUNG',
  'BPD MALUKU',
  'BPD NUSA TENGGARA BARAT',
  'BPD NUSA TENGGARA TIMUR',
  'BPD PAPUA',
  'BPD RIAU DAN KEPRI',
  'BPD SULAWESI TENGAH',
  'BPD SULAWESI TENGGARA',
  'BPD SULSELBAR',
  'BPD SULUT',
  'BPD SUMATERA BARAT',
  'BPD SUMSEL DAN BABEL',
  'BPD SUMUT',
  'CENTRATAMA NASIONAL BANK',
  'CITIBANK N.A.',
  'DEUTSCHE BANK AG',
  'HONGKONG AND SHANGHAI BANK CORPORATION',
  'JP MORGAN CHASE BANK, N.A',
  'MANDIRI E-CASH',
  'PRIMA MASTER BANK',
  'STANDARD CHARTERED BANK',
  'Bank Artha Graha',
  'Bank HSBC',
  'MUFG Bank',
  'DBS',
  'Standard Chartered Bank (SCB)',
  'Bank UOB',
  'Bank Rabobank',
  'Bank Woori Indonesia',
  'Bank Ekonomi Raharja Tbk',
  'Bank Antardaerah',
  'J Trust Bank',
  'Bank Mayapada',
  'Bank Jabar',
  'Bank BPD DIY',
  'BPD Jateng',
  'Bank Jatim',
  'Bank Aceh',
  'Bank Sumut',
  'Bank Nagari',
  'Bank BPD Riau',
  'Bank Lampung',
  'BPD Kalsel',
  'BPD Kalbar',
  'BPD Kaltim',
  'BPD Kalteng',
  'BPD Sulsel',
  'Bank Sulut',
  'Bank NTB',
  'Bank NTT',
  'Bank Bengkulu',
  'BPD Sulteng',
  'Bank Sultra',
  'Bank Swadesi',
  'Bank Muamalat',
  'Bank Mestika',
  'Bank Maspion',
  'Bank Windu Kentjana',
  'Bank QNB Indonesia(Kesawan)',
  'BTN (Bank Tabungan Negara)',
  'BANK SYARIAH INDONESIA',
  'KEB Hana Indonesia',
  'Bank Agro',
  'BPD Banten',
  'CNB',
  'Bank Harda Internasional Tbk',
  'BPR LSB',
  'BPR KS',
  'BPR EKA',
  'CTBC Indonesia(China Trust)',
  'BANGKOK BANK PUBLIC CO. LTD.',
  'BANK AGRIS',
  'BANK AMAR INDONESIA',
  'BANK ANZ INDONESIA',
  'BANK ARTA NIAGA KENCANA',
  'BANK ARTHA GRAHA INTERNATIONAL, TBK.',
  'BANK ARTOS INDONESIA',
  'BANK BCA SYARIAH',
  'BANK BISNIS INTERNASIONAL',
  'BANK BJB',
  'BANK BJB SYARIAH',
  'BANK BNI SYARIAH',
  'BANK BNP PARIBAS INDONESIA',
  'BANK BRI AGRONIAGA TBK.',
  'BANK BTPN SYARIAH, TBK.',
  'BANK BUKOPIN',
  'BANK BUMI ARTA',
  'BANK CAPITAL INDONESIA',
];

export const SgpBankList = [
  'Deutsche Bank',
  'ANZ Bank',
  'BANGKOK BANK PUBLIC COMPANY LIMITED',
  'BANK OF AMERICA, NATIONAL ASSOCIATION',
  'Bank of China',
  'BANK OF EAST ASIA LTD',
  'BANK OF INDIA',
  'Bank of Singapore',
  'BNP Paribas',
  'CHINATRUST COMMERCIAL BANK CO LTD',
  'CIMB Bank',
  'Citibank',
  'COMMERZBANK AKTIENGESELLSCHAFT',
  'CreDIT?AGRICOLE CORPORATE AND INVESTMENT BANK',
  'DBS Bank',
  'DnB?BANK ASA',
  'Far Eastern Bank',
  'FIRST COMMERCIAL BANK',
  'HL Bank',
  'HSBC Bank',
  'INDIAN BANK',
  'INDIAN OVERSEAS BANK',
  'INTESA SANPAOLO S.p.A.',
  'KOREA EXCHANGE BANK',
  'Malayan Banking (Maybank)',
  'Mizuho Bank',
  'NATIONAL AUSTRALIA BANK LTD',
  'NORDEA BANK FINLAND PLC',
  'OCBC Bank',
  'POSB Bank',
  'PT BANK NEGARA INDONESIA (PERSERO) TBK',
  'Qatar National Bank SAQ',
  'RHB Bank',
  'SKANDINAVISKA ENSKILDA BANKEN AB',
  'SOCIETE GENERALE',
  'Standard Chartered Bank',
  'State Bank of India',
  'Sumitomo Mitsui Banking Corporation',
  'SVENSKA HANDELSBANKEN AB',
  'The Bank of Tokyo-Mitsubishi UFJ',
  'UBS AG',
  'UCO BANK',
  'UOB BANK',
];
