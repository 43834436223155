import { reset } from '@/store/modules/date';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import ViewSelector from '../common/ViewSelector';
import Header from './Header';
import RefundDetailList from './RefundDetailList';

function RefundDetailContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, []);
  return (
    <Container>
      <Header />
      <RefundDetailList />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 28px 40px 85px 40px;
`;

export default RefundDetailContent;
