import { IHomeCalendarPayload, IHomeCalendarResponae } from '@/types/home';
import api from '..';

// 홈 기간 데이터
export const homeCalendar = async (params: IHomeCalendarPayload) => {
  const { startDate, endDate } = params;
  const res = await api.getAxios<IHomeCalendarPayload, IHomeCalendarResponae>(
    `/user/home?&startDate=${startDate}&endDate=${endDate}`
  );
  return res.data;
};
