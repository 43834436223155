import React, { Dispatch, SetStateAction } from 'react';
import ReactPaginate from 'react-paginate';
import './paging.css';

interface IProps {
  totalPage: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
}

interface IPage {
  selected: number;
}

const Paging = ({ setPageNumber, totalPage }: IProps) => {
  const onChage = ({ selected }: IPage) => {
    setPageNumber(selected);
  };

  return (
    <ReactPaginate
      pageCount={totalPage}
      marginPagesDisplayed={4}
      breakLabel={'...'}
      previousLabel={'<'}
      nextLabel={'>'}
      onPageChange={onChage}
      containerClassName={'paginationBttns'}
      previousLinkClassName={'priviosBttn'}
      activeLinkClassName={'nextBttn'}
      disabledClassName={'paginationDisabled'}
      activeClassName={'paginationActive'}
    />
  );
};

export default Paging;
