import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dateSelector: 'CUSTOM',
  filter: {
    startDate: new Date(),
    endDate: new Date(),
  },
  refundDetail: {
    startDate: new Date('2020-01-01'),
    endDate: new Date(),
  },
  size: 10,
  refundStatus: '',
  searchType: 'HOSPITAL_NAME',
  value: '',
};

const userSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    reset: () => initialState,
    setDateSeletor: (state, action) => {
      return {
        ...state,
        dateSelector: action.payload,
      };
    },
    setFilterSeletor: (state, action) => {
      return {
        ...state,
        filter: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    },
    setRefundDetailSeletor: (state, action) => {
      return {
        ...state,
        refundDetail: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    },
    setSizeSeletor: (state, action) => {
      return {
        ...state,
        size: action.payload,
      };
    },
    setRefundStatusSeletor: (state, action) => {
      return {
        ...state,
        refundStatus: action.payload,
      };
    },
    setValueSeletor: (state, action) => {
      return {
        ...state,
        value: action.payload,
      };
    },
    setSearchTypeSeletor: (state, action) => {
      return {
        ...state,
        searchType: action.payload,
      };
    },
  },
});

export const {
  reset,
  setDateSeletor,
  setFilterSeletor,
  setSizeSeletor,
  setRefundStatusSeletor,
  setRefundDetailSeletor,
  setValueSeletor,
  setSearchTypeSeletor,
} = userSlice.actions;
export default userSlice.reducer;
