import cancleImage from '@/assets/common/cancle.png';
import { IRefundDetailContent } from '@/types/refund';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import styled, { css } from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import Typography from '../common/Typography';
import RefundPrint from '../refund/PaymentComplete/RefundPrint';
import PrintElement from '../refund/PrintElement';

type RefundDocumentProps = {
  refund: IRefundDetailContent;
};

function RefundDocument({ refund }: RefundDocumentProps) {
  const [isPreview, setIsPreview] = useState(false);
  const printRef = useRef(null);
  return (
    <Container>
      <FlexWrap>
        <Typography size='18px' lineHeight='26px' fontWeight='500'>
          환급 서류
        </Typography>
      </FlexWrap>
      <FlexWrap alignItems='center' margin='21px 0 0 0'>
        <Typography size='16px' lineHeight='24px' fontWeight='400'>
          의료용역공급확인서
        </Typography>
        <RefundPrint
          touristIndex={refund.touristIndex}
          refundIndex={refund.refundIndex}
          setIsPreview={setIsPreview}
        />
      </FlexWrap>
      {/* 미리보기 */}
      {isPreview && (
        <PreviewWrapper>
          <PreviewContainer>
            <Cancle src={cancleImage} onClick={() => setIsPreview(false)} />
            <div ref={printRef}>
              <PrintElement
                touristIndex={refund.touristIndex}
                refundIndex={refund.refundIndex}
              />
              <ButtonWrapper>
                <ReactToPrint
                  trigger={() => <PrintBtn isPrint>출력</PrintBtn>}
                  content={() => printRef.current}
                />
                <PrintBtn onClick={() => setIsPreview(false)}>확인</PrintBtn>
              </ButtonWrapper>
            </div>
          </PreviewContainer>
        </PreviewWrapper>
      )}
    </Container>
  );
}
const Container = styled.div``;
const PreviewWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 50px;
  z-index: 30;
  overflow: auto;
`;
const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 50px;
  min-height: 100vh;
  background-color: #fff;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Cancle = styled.img<{ preview?: boolean }>`
  position: absolute;
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${(props) =>
    props.preview &&
    css`
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
    `}
`;

const PrintBtn = styled.button<{ isPrint?: boolean }>`
  bottom: 0;
  right: 0;
  padding: 13px 15px;
  border-radius: 2px;
  margin: 20px;
  width: 100px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border: 1px solid #212121;
  color: ${(props) =>
    props.isPrint ? props.theme.pointColors.white : '#212121'};
  background-color: ${(props) =>
    props.isPrint ? '#212121' : props.theme.pointColors.white};
`;
export default RefundDocument;
