import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IRefundDetailContent } from '@/types/refund';
import { getComma } from '@/util/format';
import Button from '../common/Button';
import DetailModal from './DetailModal';
import { refundDetailColor, refundDetailText } from '@/util/common';

interface IProps {
  refund: IRefundDetailContent;
  index: number;
  removeArray: number[];
  currentPage: number;
  totalElements: number;
  pageNumber: number;
  size: number;
  setRemoveArray: Dispatch<SetStateAction<number[]>>;
}

function RefundDetailItem({
  refund,
  index,
  currentPage,
  totalElements,
  size,
}: IProps) {
  const [isModal, setIsModal] = useState<any>({ isActive: false });
  const [indexNumber, setIndexNumber] = useState('');

  useEffect(() => {
    const refundIndex =
      totalElements - (currentPage * size + index) < 10
        ? '0' + String(totalElements - (currentPage * size + index))
        : String(totalElements - (currentPage * size + index));
    setIndexNumber(refundIndex);
  }, []);

  return (
    <Item key={refund.refundIndex}>
      <Content flex={0.5}>{indexNumber}</Content>
      <Content flex={1.5}>{refund.supplyDate}</Content>
      <Content flex={3}>{refund.hospitalName}</Content>
      <Content flex={1}>{refund.nation}</Content>
      <Content flex={3}>
        {(refund.touristFirstName ?? '') + ' ' + refund.touristName}
      </Content>
      <Content flex={2}>{refund.touristPassportNumber}</Content>
      <Content flex={2}>{getComma(refund.totalPrice)} 원</Content>
      <Content flex={2}>{getComma(refund.refund)} 원</Content>{' '}
      <Content flex={2}>{refund.refundCorp}</Content>
      <Content flex={2}>
        <Button
          text={refundDetailText(refund.refundStatus, refund.isTransfer)}
          color={refundDetailColor(refund.refundStatus, refund.isTransfer)}
          width='110px'
          padding='5px 0'
          border={`2px solid ${refundDetailColor(
            refund.refundStatus,
            refund.isTransfer
          )}`}
          borderRadius='4px'
          fontWeight
          backgroundColor='#FFF'
          onClick={() => setIsModal({ data: refund, isActive: true })}
        />
      </Content>
      {isModal.isActive && (
        <DetailModal refund={refund} setIsModal={setIsModal} />
      )}
    </Item>
  );
}

const Item = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Content = styled.div<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

export default RefundDetailItem;
