import { IPasswordChange } from '@/components/medicalInfo/PasswordChange';
import {
  ISigInPayload,
  ISignInResponse,
  IUploadExcelImagePayload,
} from '@/types/user';
import api from '..';

// 로그인
export const login = async (payload: ISigInPayload) => {
  const res = await api.postAxios<ISignInResponse, ISigInPayload, null>(
    '/user/sign-in',
    payload
  );
  return res.data;
};

// 로그아웃
export const logOut = async () => {
  const res = await api.deleteAxios<null, null, null>('/user/sign-out');
  return res.data;
};

// 비밀번호 변경
export const passwordChange = async (payload: IPasswordChange) => {
  const res = await api.patchAxios<boolean, IPasswordChange, null>(
    '/user/medical-info/password-change',
    payload
  );
  return res.data;
};

//의료용역공급확인서 이미지 변환 후 서버에 저장
export const uploadExel = async (payload: IUploadExcelImagePayload) => {
  const res = await api.postAxios<number, FormData, null>(
    '/user/refund/upload',
    payload.formData
  );
  return res.status;
};
