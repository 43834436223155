import { SupplyHeader } from '@/constants/supply';
import styled from 'styled-components';
import SupplyInput from './SupplyInput';

function SupplyDetail() {
  return (
    <Container>
      <Title>시술 정보 입력</Title>
      <SupplyContainer>
        <Header>
          {SupplyHeader.map((item) => (
            <HeaderTitle key={item.id} flex={item.flex}>
              {item.title}
            </HeaderTitle>
          ))}
        </Header>
        <SupplyInput />
      </SupplyContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

const SupplyContainer = styled.div`
  margin-top: 33px;
  background-color: ${(props) => props.theme.pointColors.white};
  @media ${(props) => props.theme.laptop} {
    margin-top: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #eaf0fa;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const HeaderTitle = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  flex: ${(props) => props.flex};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

export default SupplyDetail;
