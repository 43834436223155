import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useForm, useFormContext } from 'react-hook-form';

import { passportValidate } from '@/api/tourist/indext';
import getCurrentTime, { getNumberFullTime } from '@/util/getCurrentTime';
import ErrorMessage from '../common/ErrorMessage';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { touristIndexState } from '@/store/modules/user';
import LoadingView from '../common/LoadingView';

import { attachedHyphensDate } from '@/util/format';
import {
  IPassportValidatePayload,
  IPassportValidateResponse,
} from '@/types/refund';
import { getRefundAlertMessage } from '@/util/errorMessage';
import FlexWrap from '../common/FlexWrap';
import DateSelector from '../common/DateSelector';

interface IProps {
  isValidate: boolean;
  setIsValidate: Dispatch<SetStateAction<boolean>>;
}
export interface ITourist {
  supplyDate: string;
  firstName: string;
  name: string;
  nationality: string;
  passportNumber: string;
  saleDate: string;
  birthDate: string;
}

function TouristInput({ isValidate, setIsValidate }: IProps) {
  const [isActive, setIsActive] = useState(false);
  const [validatetErrorMessage, setValidateErrorMessage] = useState('');
  const [isActiveCalendar, setIsActiveCalendar] = useState(false);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      supplyDate: attachedHyphensDate(getNumberFullTime(new Date())),
      firstName: '',
      name: '',
      nationality: '',
      passportNumber: '',
      saleDate: getCurrentTime(),
      birthDate: '',
    },
  });

  const watchAllFields = watch();
  useEffect(() => {
    const {
      birthDate,
      firstName,
      name,
      nationality,
      passportNumber,
      saleDate,
    } = getValues();
    birthDate &&
    firstName &&
    name &&
    nationality &&
    passportNumber &&
    saleDate &&
    !isValidate
      ? setIsActive(true)
      : setIsActive(false);
  }, [watchAllFields]);

  const method = useFormContext();
  const { mutate: validateMutation, isLoading } = useMutation<
    IPassportValidateResponse,
    AxiosError,
    IPassportValidatePayload
  >((payload) => passportValidate(payload), {
    retry: false,
    onSuccess: async (data) => {
      setIsValidate(true);
      setIsActive(false);
      setValidateErrorMessage('');
      method.setValue('supplyDate', getValues('supplyDate'));
      dispatch(touristIndexState(data));
      // 여권인증 유효기간
      !!data.rightExpireDate
        ? alert('여권 인증에 성공 하였습니다.')
        : alert(
            '여권 인증이 확인 되었습니다.\n3개월 이상 비자 소지자로 3개월 내 출국여부를 확인해주세요. '
          );
    },
    onError: (error: any) => {
      const message = error.response.data.message;
      const code = error.response.data.code;
      if (message.includes('4005')) {
        setError('passportNumber', {
          message: '존재하지 않는 여권번호입니다. 다시 확인해주세요.',
        });
        return;
      }
      if (message.includes('4001')) {
        setError('passportNumber', {
          message: '국적코드와 여권번호가 일치하지 않습니다',
        });
        return;
      }
      if (['T0002', 'T0003'].includes(code)) {
        setValidateErrorMessage('환급 대상이 아니므로 환급이 불가합니다.');
        return;
      }
      alert(getRefundAlertMessage(message));
    },
  });

  const checkSupplyDate = (supplyDate: string) => {
    const toDayDate = getNumberFullTime(new Date());
    const currentSupplyDate = supplyDate.replaceAll('-', '');
    if (currentSupplyDate > toDayDate) {
      setError('supplyDate', {
        message: '공급일자를 다시 확인해주세요',
      });
      return false;
    }
    return true;
  };

  const onSubmit = async ({
    supplyDate,
    firstName,
    name,
    nationality,
    passportNumber,
    birthDate,
    saleDate,
  }: ITourist) => {
    if (!checkSupplyDate(supplyDate)) return;
    const regex =
      /^(19[0-9]{2}|20[0-2][0-9])(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
    if (!regex.test(birthDate.replaceAll('-', ''))) {
      setError('birthDate', {
        message: '올바른 생년월일을 입력해주세요.',
      });
      return;
    }
    const payload = {
      supplyDate,
      firstName: firstName.toUpperCase(),
      name: name.toUpperCase(),
      nationality: nationality.toUpperCase(),
      passportNumber: passportNumber.toUpperCase(),
      birthDate,
      saleDate,
    };
    validateMutation(payload);
  };

  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsValidate(false);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>외국인 관광객 정보</Title>
        <InputWrapper>
          <InputContainer>
            <InputContent>
              <Label>공급일자</Label>
              <Input
                {...register('supplyDate', {
                  required: true,
                })}
                maxLength={10}
                type='text'
                disabled={isValidate}
                placeholder='YYYYMMDD'
                autoComplete='off'
                onClick={() => setIsActiveCalendar(true)}
                readOnly
                style={{ cursor: 'pointer' }}
              />
              <DateContainer>
                <DateSelector
                  setValue={setValue}
                  isActiveCalendar={isActiveCalendar}
                  setIsActiveCalendar={setIsActiveCalendar}
                />
              </DateContainer>
              {errors.supplyDate && (
                <ErrorMessage text={errors.supplyDate.message!} absolute />
              )}
            </InputContent>
            <InputContent />
          </InputContainer>
          <InputContainer>
            <InputContent>
              <Label>국적</Label>
              <Input
                {...register('nationality', {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: '영문으로 입력해주세요.',
                  },
                })}
                type='text'
                disabled={isValidate}
                placeholder='국가코드 (여권 상단 3글자 알파벳)'
                autoComplete='off'
                maxLength={3}
              />
              {errors.nationality && (
                <ErrorMessage text={errors.nationality.message!} absolute />
              )}
            </InputContent>
            <InputContent>
              <Label>여권번호</Label>
              <Input
                {...register('passportNumber', { required: true })}
                type='text'
                disabled={isValidate}
                placeholder='여권번호 입력'
                autoComplete='off'
                autoCapitalize='characters'
              />
              {errors.passportNumber && (
                <ErrorMessage text={errors.passportNumber.message!} absolute />
              )}
            </InputContent>
          </InputContainer>
          <InputContainer>
            <InputContent>
              <Label>생년월일</Label>
              <Input
                {...register('birthDate', {
                  required: true,
                  minLength: {
                    value: 10,
                    message: '생년월일은 8자리입니다. 다시 입력해주세요.',
                  },
                })}
                maxLength={10}
                type='text'
                disabled={isValidate}
                placeholder='YYYYMMDD'
                autoComplete='off'
                onChange={(e) =>
                  setValue('birthDate', attachedHyphensDate(e.target.value))
                }
              />
              {errors.birthDate && (
                <ErrorMessage text={errors.birthDate.message!} absolute />
              )}
            </InputContent>

            <InputContent>
              <Label>성명(영문)</Label>
              <InputItemContainer>
                <Input
                  {...register('firstName', {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9 ]*$/,
                      message: '영문으로 입력해주세요.',
                    },
                  })}
                  type='text'
                  disabled={isValidate}
                  placeholder='성 입력'
                  autoComplete='off'
                  isName
                />
                {errors.firstName && (
                  <ErrorMessage text={errors.firstName.message!} absolute />
                )}
                <Input
                  {...register('name', {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9 ]*$/,
                      message: '영문으로 입력해주세요.+',
                    },
                  })}
                  type='text'
                  disabled={isValidate}
                  placeholder='이름 입력'
                  autoComplete='off'
                  isName
                />
                {errors.name && (
                  <ErrorMessage text={errors.name.message!} absolute />
                )}
              </InputItemContainer>
            </InputContent>
          </InputContainer>
        </InputWrapper>
        <ButtonContainer>
          {validatetErrorMessage && (
            <ErrorMessage text={validatetErrorMessage} margin='0 10px 0 0' />
          )}
          <Button
            isActive={isActive}
            isValidate={isValidate}
            disabled={isLoading}
            onClick={isValidate ? onEdit : handleSubmit(onSubmit)}
          >
            {isLoading ? <LoadingView /> : isValidate ? '수정' : '확인'}
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 38px 52px;
  background-color: ${(props) => props.theme.pointColors.white};
  border: 1px solid #cbccce;
  @media ${(props) => props.theme.laptop} {
    gap: 18px;
    padding: 38px 20px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 250px;
  position: relative;
`;
const InputItemContainer = styled(FlexWrap)`
  flex: 1;
  justify-content: space-between;
  width: 80%;
  gap: 4%;
`;
const InputForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;
const InputContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  font-weight: ${(props) => props.theme.fontWeight.medium};
  width: 20%;
`;

const Input = styled.input<{ isName?: boolean }>`
  display: flex;
  width: 80%;
  height: 48px;
  border-radius: 4px;
  padding-left: 10px;
  outline: none;
  text-transform: uppercase;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};

  :disabled {
    background-color: ${(props) => props.theme.grayColors.scale4};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 28px;
  @media ${(props) => props.theme.laptop} {
    margin-top: 20px;
  }
`;

const Button = styled.button<{ isActive?: boolean; isValidate?: boolean }>`
  width: 100px;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.isValidate || props.isActive
      ? props.theme.pointColors.white
      : props.theme.grayColors.scale2};
  background-color: ${(props) =>
    props.isValidate
      ? '#0b42ad'
      : props.isActive
      ? props.theme.grayColors.scale1
      : props.theme.grayColors.scale4};
`;
const DateContainer = styled.div`
  position: absolute;
  top: 50%;
  top: 12px;
  right: 0px;
  /* transform: translateY(-50%); */
  z-index: 10;
  min-height: 200px;
`;
export default TouristInput;
